<template>
    <div class="flex-1 flex flex-col pb-4" @click="onSelected">
        <img class="object-cover pointer-events-none group-hover:opacity-75 rounded-t-lg" :src="thumbUrl">
        <h3 class="mt-6 ml-6 mr-6 text-white text-md font-medium">{{ pName }}</h3>
        <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-yellow-500 text-sm">{{ pDesc}}</dd>
            <dd class="text-gray-400 text-sm">{{ dict.duration }}: {{ product.total_duration }}</dd>
            <dd class="mt-1">
                <span class="px-2 py-1 text-white text-xs font-medium bg-red-500 rounded-full">
                    {{dict.txt_price}}: AUD ${{product.price_aud}}/人民币: {{product.price_aud*5}}元({{product.price_aud*5*15/20}}币)
                </span>
            </dd>
        </dl>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name:'ProductCard',
    props:{
        product:{
            type: Object,
            required: true,
        },
        base:{
            type: String,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['dict','current_lang']),
        thumbUrl: function(){
            return this.base + this.product.images[0];
        },
        pName: function(){
            return this.current_lang === 'cn' ? this.product.name : this.product.name_en;
        },
        pDesc: function(){
            return this.current_lang === 'cn' ? this.product.description : this.product.description_en;
        }
    },
    methods:{
        onSelected: function(){
            this.$emit('on-selected',{product: this.product});
        }
    }
}
</script>