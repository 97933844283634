<template>
    <div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
      <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0" aria-hidden="true"></div>
    
        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <div class="w-screen max-w-md">
            <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div class="flex-1 h-0 overflow-y-auto">
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">
                      卡密充值的方式
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex-1 flex flex-col justify-between">
                  <div class="px-4 divide-y divide-gray-200 sm:px-6">
                    <div class="space-y-3 pt-6 pb-5">
                        <p>
                          1: 点击下面"购买卡密"按钮，然后选择您所需的金额. 购买过程中<b class="text-red-600">不要关闭浏览器</b>，付款成功后,等待几秒钟会看到卡号和卡密.
                        </p>
                        <p>
                          2: 将您所获得的<b class="text-red-600">卡号或卡密</b>,填入下方的输入框中,点击充值即可
                        </p>
                        <p class="text-gray-600 text-sm">如果不小心关闭或未及时收到卡密，可以通过售卡平台页面的订单查询对话框输入购买时的必填的联系方式或订单编号（订单编号就是用支付宝或者微信支付以后生成的账单详情，里面有商家订单号）, 就可以查询到您购买到的卡号和卡密了。
                        </p>
                        <p class="mt-0 text-sm">
                            在购买卡密后，如果充值遇到问题, 请联系客服邮件<a class="text-blue-600" :href="'mailto:'+mailLink">{{ mailLink }}</a>.<span class="text-red-600">写上您在本站的账户邮箱地址, 以及您的卡密, 并耐心等待</span>.我们会尽快帮您解决。请不要恶意投诉，否则账号直接拉黑.
                        </p>
                        <hr>
                        <p class="text-sm leading-6">
                            <button v-for="(payUrl, idx) in pay_urls" @click="contact($event,payUrl)" :key="'pu-'+idx" class="ml-1 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >购买卡密 <span v-if="idx>0">(备用)</span>
                            </button>
                        </p>
                        <hr>
                        <div>
                          <div class="mt-1">
                              <input v-model="km" placeholder="16位长的卡号或8位卡密填写到这里" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          </div>
                        </div>
                        <p class="text-sm leading-6">
                          <button @click="checkLastKami($event)" type="button" class="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            2:用卡号充值
                          </button>
                        </p>
                    </div>
                    <div v-if="current_lang!=='cn'">
                      <img src="line.jpeg" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                <span>
                    <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ dict.GoBack }}
                    </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </template>
    <script>
    import {mapGetters,mapMutations,mapActions} from 'vuex';
    import Helpers from "@/common/Helpers";
    export default {
        name: 'ThirdPartyCard',
        props:{
            visible:{
                type: Boolean,
                required: true,
            }
        },
        computed:{
            ...mapGetters(['dict','config','current_lang','pay_urls']),
            mailLink: function(){
              return this.config.qq + '@gmail.com'
            },
            kmValid: function(){
              return this.km.trim().length === 16 || this.km.trim().length === 8
            }
        },
        data(){
            return {
              isCheckingKami: false,
              km:'',
            }
        },
        methods:{
          ...mapMutations(['Notify']),
          ...mapActions(['VerifyKami']),
            backToPrev: function(e){
                e.preventDefault();
                this.$emit('cancelled');
            },
            contact: function(e, channel){
                e.preventDefault();
                if(channel !== '#'){
                  window.open(channel, '_blank');
                }
            },
            checkLastKami: function(e){
              e.preventDefault();
              if(!this.kmValid){
                this.Notify(Helpers.errorNotification('卡密不对, 请检查您是否输入了为大写字母的16位长度的卡号'))
                return
              }
              if(!this.isCheckingKami){
                this.isCheckingKami = true;
                // Todo: 开始查询
                this.VerifyKami(this.km.trim()).then(res => {
                  if(res.data.result === true){
                    // 已经成功了
                    this.Notify(Helpers.successNotification('充值成功'))
                    location.reload();
                  }else{
                    // 兑换失败
                    this.isCheckingKami = false;
                    this.Notify(Helpers.errorNotification('卡密不对'))
                  }
                }).catch(err => {
                  this.Notify(Helpers.errorNotification(err));
                  this.isCheckingKami = false;
                })
              }
            }
        }
    }
    </script>
    <style scoped>
    
    </style>