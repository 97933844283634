<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
    <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0" aria-hidden="true"></div>

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
        <div class="w-screen max-w-md">
            <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                    <div class="py-6 px-4 bg-gray-800 sm:px-6">
                        <div class="flex items-center justify-between">
                            <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            {{ dict.txt_apple_title }}
                            </h2>

                            <div class="ml-3 h-7 flex items-center">
                            <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            </div>
                        </div>
                    </div>

                    <div class="px-5 mt-2">
                        <p class="text-sm leading-6 mt-4 mb-4">
                            {{ dict.txt_apple_subtitle }}
                        </p>
                        <ol>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_apple_step1"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_apple_step2"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_apple_step3"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_apple_step4"></p></li>
                        </ol>
                        <p class="text-sm leading-6 mt-4 mb-4">
                            {{ dict.txt_apple_note }}
                        </p>
                        <hr>
                        <p class="text-sm leading-6 mt-4 mb-4 text-red-500">
                            <br><b class="text-blue-500">Email: {{ config.qq }}@gmail.com</b>
                        </p>
                    </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <span>
                        <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ dict.GoBack }}
                        </button>
                    </span>
                </div>
            </form>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
    name:'AppleCard',
    props:{
        visible: {
            type: Boolean,
            required: true,
        },
    },
    computed:{
        ...mapGetters(['dict','config']),
    },
    methods:{
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
    }
}
</script>
<style scoped>

</style>