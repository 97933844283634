<template>
  <div class="bg-black flex flex-col sm:px-6 lg:px-8 h-screen">
    <Videos @video-selected="onVideoSelected" :type="type"></Videos>
  </div>
</template>

<script>
import Constants from '../common/Constants';
import Videos from "../components/Videos.vue";
import {mapMutations} from 'vuex';

export default {
  name: "Home",
  components: {
    Videos
  },
  data(){
    return {
      type: Constants.LIST_TYPE.SIMPLE
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.UpdateCurrentPageVideosType(this.type); // 更新一下当前的视频类型
    });
  },
  methods:{
    ...mapMutations(['UpdateCurrentPageVideosType']),
    onVideoSelected: function(payload){
      if(payload.loggedIn){
        this.$router.push({
            name:'Watch',params:{id: payload.video.i}
        });
      }else{
        this.$router.push({
            path:'/sign-in'
        });
      }
    },
  }
};
</script>

<style scoped>
body{
  background-color: black;
}
</style>