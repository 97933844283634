<template>
    <div class="mt-1 flex relative">
        <div class="inline-flex items-center px-2 text-sm font-medium bg-red-500 text-white" :class="active?'rounded-l-md':'rounded-md'">
            <div class="flex" @click="toggleDanmu">
                <svg class="-ml-0.5 mr-1.5 mt-0.5 h-4 w-4" :class="active ? 'text-green-500' : 'text-red-800'" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                </svg>
                {{ dict.txt_dm_dan }}
            </div>
        </div>
        <div v-if="active" class="inline-flex items-center px-1 rounded-r-md text-sm font-medium bg-gray-500 text-white" >
            <div class="flex" @click="startWrite">
                {{ dict.txt_dm_write }}
            </div>
        </div>

        <div class="fixed inset-0 overflow-hidden z-50" v-if="showTalkTrigger">
            <div class="absolute inset-0 overflow-hidden">
                <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <div class="w-screen max-w-md">
                        <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                        <div class="px-4 sm:px-6">
                            <div class="flex items-start justify-between">
                            <h2 class="text-md font-medium text-gray-900" id="slide-over-title">
                                {{ dict.txt_dm_form_title }}
                            </h2>
                            <div class="ml-3 h-7 flex items-center">
                                <button @click="showTalkTrigger = false" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                </button>
                            </div>
                            </div>
                        </div>
                        <div class="mt-6 relative inline-block px-4 sm:px-6">
                            <div class="mt-1">
                                <textarea v-model="input" :placeholder="dict.txt_dm_form_title" rows="4" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"></textarea>
                            </div>
                            <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                            <button @click="sendBarrage($event)" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {{ dict.txt_Send }}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Helpers from '../../../common/Helpers';
export default {
    name:'Danmu',
    computed:{
        ...mapGetters(['current_video','dict'])
    },
    watch:{
        // 监控vuex中的变化的可用方式
        '$store.getters.current_video_offset': function(){
            if(this.active){
                // 如果弹幕是激活的状态，则更新弹幕集合
                this.refreshDiss();
            }
        }
    },
    data(){
        return {
            active: false, // 表示是否激活, 默认都是激活的
            showTalkTrigger: false, // 是否显示发弹幕的文字
            input: '',
            search:'', // emoji 所要求的
            sending: false,
        }
    },
    mounted(){
        // this.active = false;
    },
    methods:{
        ...mapActions(['LoadDiss','PushDiss']),
        ...mapMutations(['UpdateDiss','Notify']),
        toggleDanmu: function(){
            this.active = !this.active;
            this.refreshDiss();
        },
        startWrite: function(){
            // 发弹幕前，先停止弹幕的播放
            // this.active = false;
            // this.refreshDiss();
            this.showTalkTrigger = true;
            // this.content = 'ccc ' + new Date().toDateString();
            // this.PushDiss(this.content);
            // this.$emit('diss-updated-local',{c: this.content});
            // this.content = '';
        },
        sendBarrage: function(e){
            e.preventDefault();
            if(this.input.trim() === ''){
                return;
            }
            if(!this.sending){
                this.sending = true;
                this.$emit('diss-updated-local',{c: this.input});
                this.showTalkTrigger = false; // 关闭输入框
                this.PushDiss(this.input).then(() => {
                    this.input = '';
                }).catch(err => {
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                }).finally(()=>{
                    setTimeout(()=>{
                        this.sending = false;
                    }, 1500);
                });
            }
        },
        refreshDiss: function(){
            if(this.active){
                // 打开了弹幕开关，则重新加载弹幕
                this.LoadDiss().then(()=>{
                    this.$emit('diss-updated',{active: this.active});
                });
            }else{
                // 关闭弹幕
                this.UpdateDiss([]);
                this.$emit('diss-updated',{active: this.active});
            }
        },
        insert(emoji) {
            this.input += emoji
        },
        append(emoji) {
            this.input += emoji
        },
    }
}
</script>
<style scoped>
.diss-wrap{
    width: 100%;
    height: 50px;
    top: 100px;
    right: 100px;
    z-index: 9999;
}
.diss{
    z-index: 9999;
}
</style>