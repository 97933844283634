<template>
    <div class="sm:hidden fixed w-full left-0 bottom-0 bg-gray-900 border-t-3 border-red-200 shadow">
        <Pagination @pn-updated="reloadVideos" :search="search" :type="type"></Pagination>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import Pagination from './Widgets/Pagination.vue';
export default {
    name: 'FooterBar',
    components:{
        Pagination
    },
    props:{
        search: {
            type: Boolean,
            required: false,
            default: function(){
                return false;
            }
        },
        type:{
            type: Number,
            required: true,
        }
    },
    data(){
        return {showMore:false}
    },
    methods:{
        ...mapActions(['FetchVideos']),
        reloadVideos: function(payload){
            this.$emit('pn-updated', payload);
        }
    }
}
</script>
<style scoped>

</style>