<template>
  <div class="bg-black flex flex-col py-0 sm:px-6 lg:px-8 h-screen">
    <div class="grid grid-cols-1">
        <div class="shadow sm:rounded-lg">
            <div class="px-4 py-0">
                <form class="mt-5 flex sm:items-center">
                    <div class="w-full sm:max-w-xs">
                        <input type="text" v-model="query"
                            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-l-md" 
                            placeholder="请输入"
                        >
                    </div>
                    <button @click="search($event)" type="submit" class="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </button>
                </form>
            </div>
        </div>
        
        <div v-if="ready">
            <p class="text-red-500 text-center mt-4 mb-4" v-if="current_total !== null">
                {{ searching ? dict.txt_Searching : dict.txt_Result_Found }} 
                <span>({{current_total * 18 + dict.txt_Items}})</span>
            </p>
            <Videos @video-selected="onVideoSelected" :type="vListType" :search="true" @on-category-clicked="newSearch"></Videos>
        </div>
    </div>
  </div>
</template>
<script>
import Constants from '../common/Constants';
import {mapGetters,mapActions,mapMutations} from 'vuex';
import Videos from "../components/Videos.vue";
import Helpers from '../common/Helpers';

export default {
    name: 'Search',
    components:{
        Videos
    },
    computed:{
        ...mapGetters(['dict','user','last_search_query']),
    },
    data(){
        return {
            ready: false,
            query: '',
            searching: false,
            current_total: null,
            vListType:Constants.LIST_TYPE.SEARCH,
        }
    },
    created(){
        setTimeout(()=>{
            this.ready = true;
        },300);
    },
    mounted(){
        this.UpdateCurrentPageVideosType(this.vListType); // 更新一下当前的视频类型
        if(this.$route.params.keyword == '0'){
            this.query = this.last_search_query;
        }
        else{
            this.query = this.$route.params.keyword;
            this.UpdateCurentPageNumber({
                  num: 1,
                  param: '',
                });
            this.search();
        }
    },
    methods:{
        ...mapActions(['SearchAction']),
        ...mapMutations(['UpdateLastSearchQuery','UpdateCurrentPageVideosType','UpdateCurentPageNumber','Notify']),
        onVideoSelected: function(payload){
            if(payload.loggedIn){
                this.$router.push({
                    name:'Watch',params:{id: payload.video.i}
                });
            }else{
                this.$router.push({
                    path:'/sign-in'
                });
            }
        },
        search: function(e){
            if(e){
                e.preventDefault();
                // 这个是按钮的直接点击，需要更新页码
                this.UpdateCurentPageNumber({
                  num: 1,
                  param: '',
                });
            }
            
            const q = this.query.trim();
            if(q===''){
                const msg = Helpers.noteNotification('请输入搜索的关键字');
                this.Notify(msg);
                return;
            }
            if(!this.searching){
                this.searching = true;
                this.current_total = null;
                this.UpdateLastSearchQuery(q);
                this.SearchAction().then((data) => {
                    this.current_total = data.total;
                }).finally(()=>{
                    this.searching = false;
                });
            }
        },
        newSearch: function(payload){
            this.query = payload.n;
            this.search();
        }
    }
}
</script>