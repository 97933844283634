<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
    <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0" aria-hidden="true"></div>

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
        <div class="w-screen max-w-md">
            <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                    <div class="py-6 px-4 bg-gray-800 sm:px-6">
                        <div class="flex items-center justify-between">
                            <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            {{ dict.txt_gift_title }}
                            </h2>

                            <div class="ml-3 h-7 flex items-center">
                            <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            </div>
                        </div>
                    </div>

                    <div class="px-4 mt-2">
                        <p class="text-sm leading-6 mt-4 mb-4">
                            {{ dict.txt_gift_subtitle }}
                        </p>
                        <p>
                            <a href="https://cnfemdomtube.com/aeca1.png" target="_blank">
                                <img src="https://cnfemdomtube.com/aeca1.png">
                            </a>
                        </p>
                        <ol>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_gift_step1"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_gift_step2"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_gift_step3"></p></li>
                            <li><p class="text-sm leading-6 mt-4 mb-4" v-html="dict.txt_gift_step4"></p></li>
                        </ol>
                        <p class="text-sm leading-6 mt-4 mb-4">
                            {{ dict.txt_gift_note }}
                        </p>
                        <hr>
                        <p class="text-sm leading-6 mt-4 mb-4 text-red-500">
                            <br><b class="text-blue-500">Email: {{ config.qq }}@gmail.com</b>
                        </p>
                    </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <span>
                        <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ dict.GoBack }}
                        </button>
                    </span>
                </div>
            </form>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Constants from '../../../common/Constants';
import Helpers from '../../../common/Helpers';

export default {
    name:'EgiftCard',
    props:{
        visible: {
            type: Boolean,
            required: true,
        },
    },
    computed:{
        ...mapGetters(['dict','order','config','current_video']),
        priceTxtMonthly: function(){
            return Helpers.priceText(this.config.vip_price * (1-this.discount_month), false);
        },
        priceTxtSeason: function(){
            return Helpers.priceText(this.config.vip_price * 3 * (1-this.discount_season), false);
        },
        priceTxtAnnual: function(){
            return Helpers.priceText(this.config.vip_price * 12 * (1-this.discount_annual), false);
        },
        vipOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.VIP;
        },
        collectionOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.COLLECTION;
        },
        customVideoOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.CUSTOM_VIDEO;
        },
        priceText: function(){
            return Helpers.priceText(this.order.price, false);
        }
    },
    data(){
        return {
            discount_month: 0, // 月付折扣
            discount_season: 0.05, // 季度折扣
            discount_annual: 0.1, // 年付折扣
            quantity: '1',
            paymentStarted: false, // 标识支付是否已经发起
            activating: false,
            wait: false,
        }
    },
    methods:{
        ...mapMutations(['Notify']),
        ...mapActions(['SubmitToCreateOrder','ActivateMyVip']),
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
        getPayUrl: function(e){
            e.preventDefault();
            if(!this.wait){
                this.wait = true;
                this.SubmitToCreateOrder(this.quantity).then(res => {
                    // 返回了2个数据，一个是支付页面的后缀，一个是订单号
                    // window.open(this.config.payment_url + res.s + '/' + res.id, '_blank');
                    window.location.href = res.re;
                    this.paymentStarted = true;
                }).catch(err => {
                    const msg = Helpers.errorNotification(err + ': '+this.dict.direct_pay_error);
                    this.Notify(msg);
                }).finally(() => {
                    setTimeout(()=>{
                        this.wait = false;
                    }, 2200);
                });
            }
        },
        menuallyActivate: function(e){
            e.preventDefault();
            if(!this.activating){
                this.activating = true;
                this.ActivateMyVip().then(() => {
                    const msg = Helpers.successNotification(this.dict.txt_activate_success);
                    this.Notify(msg);
                }).catch(err => {
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                }).finally(()=>{
                    this.activating = false;
                });
            }
        }
    }
}
</script>
<style scoped>

</style>