import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VideoPlayerPage from "../views/VideoPlayerPage.vue";
import MySpace from "../views/MySpace.vue";
import MyFavorite from "../views/MyFavorite.vue";
import Search from "../views/Search.vue";
import Queens from "../views/Queens.vue";
import Collection from "../views/Collection.vue";
import Application from "../views/Application.vue";
import Age from "../views/Age.vue";
import Retrieve from "../views/Retrieve.vue";

Vue.use(VueRouter);
import Store from '../store/index.js';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/custom",
    name: "Custom",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Custom.vue"),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import( "../views/SignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () =>
      import("../views/SignUp.vue"),
  },
  {
    path: "/watch/:id",
    name: "Watch",
    component: VideoPlayerPage,
  },
  {
    path: "/myspace",
    name: "MySpace",
    component: MySpace,
  },
  {
    path: "/myfavorite",
    name: "MyFavorite",
    component: MyFavorite,
  },
  {
    path: "/search/:keyword",
    name: "Search",
    component: Search,
  },
  {
    path: "/mistress",
    name: "Mistress",
    component: Queens,
  },
  {
    path: "/collection",
    name: "Collection",
    component: Collection,
  },
  {
    path: "/join",
    name: "Application",
    component: Application,
  },
  {
    path: "/age",
    name: "Age",
    component: Age,
  },
  {
    path: "/retrieve",
    name: "Retrieve",
    component: Retrieve, // 资源回收
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if(from.name === 'Watch' && to){
    Store.commit('PauseVideo');
  }
  Store.commit('UpdateSubmenuVisible',false);
  // 检查是否已经确认自己在18岁以上
  if((localStorage.age === undefined || localStorage.age !== 'y') && to.name !== 'Age'){
    router.push({
        path:'/age'
    });
  }

  next();
})

export default router;