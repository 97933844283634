<template>
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="visible">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <Spinner cls="text-gray-600" :visible="true"></Spinner>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            {{ text }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from './Spinner.vue';
export default {
    name:'Overlay',
    components:{
        Spinner
    },
    props:{
        text:{
            type: String,
            required: false,
            default: function(){
                return "Loading ...";
            }
        },
        visible: {
            type: Boolean,
            required:true,
        }
    },
}
</script>