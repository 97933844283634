<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
    <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0" aria-hidden="true"></div>

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
        <div class="w-screen max-w-md">
            <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                    <div class="py-6 px-4 bg-gray-800 sm:px-6">
                        <div class="flex items-center justify-between">
                            <h2 class="text-lg font-medium text-white" id="slide-over-title">
                            {{ dict.redeem_widget_title }}
                            </h2>
                            <div class="ml-3 h-7 flex items-center">
                            <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            </div>
                        </div>
                    </div>

                    <div class="px-4 mt-2">
                        <fieldset>
                            <div class="space-y-4" v-if="vipOrder">
                              <label :class="-2==quantity ? 'bg-gray-800' : 'bg-white'"
                                  class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-2 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between">
                                <input type="radio" v-model="quantity" value="-2" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                                <div class="flex items-center">
                                  <div class="text-sm">
                                    <p  class="font-medium" :class="-2==quantity ? 'text-white' : 'text-gray-900'">
                                      2周的VIP
                                    </p>
                                  </div>
                                </div>
                                <div  class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                  <div class="font-medium" :class="-2==quantity ? 'text-white' : 'text-gray-900'">{{ priceTxtFortnight }}</div>
                                  <div class="ml-1 sm:ml-0" :class="-2==quantity ? 'text-white' : 'text-gray-900'">/2周</div>
                                </div>
                              </label>
                                <label :class="1==quantity ? 'bg-gray-800' : 'bg-white'"
                                    class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-2 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between">
                                    <input type="radio" v-model="quantity" value="1" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                        <p class="font-medium" :class="1==quantity ? 'text-white' : 'text-gray-900'">
                                            4周的VIP
                                        </p>
                                        </div>
                                    </div>
                                    <div  class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div class="font-medium" :class="1==quantity ? 'text-white' : 'text-gray-900'">{{ priceTxtMonthly }}</div>
                                        <div class="ml-1 sm:ml-0" :class="1==quantity ? 'text-white' : 'text-gray-900'">/4周</div>
                                    </div>
                                </label>
                                <label :class="3==quantity ? 'bg-gray-800' : 'bg-white'"
                                    class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-2 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between">
                                    <input type="radio" v-model="quantity" value="3" class="sr-only" aria-labelledby="server-size-1-label" aria-describedby="server-size-1-description-0 server-size-1-description-1">
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                        <p id="server-size-1-label" class="font-medium" :class="3==quantity ? 'text-white' : 'text-gray-900'">
                                          12周的VIP (优惠 <span class="text-red-500">{{ config.discount_season * 100 }}%</span>)
                                        </p>
                                        </div>
                                    </div>
                                    <div id="server-size-1-description-1" class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div class="font-medium" :class="3==quantity ? 'text-white' : 'text-gray-900'">{{ priceTxtSeason }}</div>
                                        <div class="ml-1 sm:ml-0" :class="3==quantity ? 'text-white' : 'text-gray-900'">/12周</div>
                                    </div>
                                </label>
                                <label :class="12==quantity ? 'bg-gray-800' : 'bg-white'"
                                    class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-2 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between">
                                    <input type="radio" v-model="quantity" value="12" class="sr-only" aria-labelledby="server-size-2-label" aria-describedby="server-size-2-description-0 server-size-2-description-1">
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                        <p id="server-size-2-label" class="font-medium" :class="12==quantity ? 'text-white' : 'text-gray-900'">
                                            1年的VIP (优惠 <span class="text-red-500">{{ config.discount_annual * 100 }}%</span>)
                                        </p>
                                        </div>
                                    </div>
                                    <div id="server-size-2-description-1" class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div class="font-medium" :class="12==quantity ? 'text-white' : 'text-gray-900'">{{ priceTxtAnnual }}</div>
                                        <div class="ml-1 sm:ml-0" :class="12==quantity ? 'text-white' : 'text-gray-500'">/年</div>
                                    </div>
                                </label>
                            </div>
                            <div class="space-y-4" v-if="collectionOrder">
                                <label class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <input type="radio" v-model="quantity" value="1" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                        <p  class="font-medium text-gray-900">
                                            {{ dict.txt_collection }}
                                        </p>
                                        </div>
                                    </div>
                                    <div  class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div class="font-medium text-gray-900">{{ collectionPriceTxt }}</div>
                                        <div class="ml-1 text-gray-500 sm:ml-0">/{{ dict.txt_collection }}</div>
                                    </div>
                                </label>
                            </div>
                            <div class="space-y-4" v-if="customVideoOrder">
                                <label class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <input type="radio" v-model="quantity" value="1" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                        <p  class="font-medium text-gray-900">
                                            {{ dict.txt_custom_video }}: <b class="text-blue-500">{{ this.current_video.t }}</b>
                                        </p>
                                        </div>
                                    </div>
                                    <div  class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div class="font-medium text-gray-900">{{ customVtxt }}</div>
                                        <div class="ml-1 text-gray-500 sm:ml-0"></div>
                                    </div>
                                </label>
                            </div>

                            <div class="space-y-1" v-if="packageTypeOrder">
                                <label
                                    v-for="multi in packageMultiplex" :key="'multi'+multi"
                                    :class="multi==quantity ? 'bg-gray-800' : 'bg-white'"
                                    class="relative block rounded-lg border border-gray-300 shadow-sm px-6 py-1 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between">
                                  <input type="radio" v-model="quantity" :value="multi" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                                  <div class="flex items-center">
                                    <div class="text-sm">
                                      <p :class="multi==quantity ? 'text-white' : 'text-gray-900'">
                                        {{ multi*15 }}个字母币, 可用来兑换视频
                                      </p>
                                    </div>
                                  </div>
                                  <div  class="mt-1 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                    <div class="text-red-500">{{ calculatePackagePrice(multi) }}</div>
                                  </div>
                                </label>
                            </div>
                        </fieldset>

                        <p class="text-sm leading-6 mt-4 mb-4">
                            <button @click="getPayUrl($event)"
                            class="inline-flex items-center px-6 py-4 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                            <Spinner :visible="wait"></Spinner>
                            {{ dict.txt_confirm_to_pay }}
                            </button>
                        </p>
                        <hr>
                        <p class="text-sm leading-6 mt-4 mb-4 text-red-500">
                            {{ dict.direct_pay_notes }} <b class="text-blue-500">{{ config.qq }}@gmail.com</b>
                        </p>
                        <p class="text-sm leading-6 mt-4 mb-4">
                            <button @click="manuallyActivate($event)"
                            class="inline-flex items-center px-6 py-4 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm bg-red-600 hover:bg-red-700 text-white focus:outline-none"
                            >
                            <Spinner :visible="activating"></Spinner>
                            {{ dict.txt_activate_btn }}
                            </button>
                        </p>
                    </div>
                </div>

                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <span>
                        <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ dict.GoBack }}
                        </button>
                    </span>
                </div>
            </form>
        </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Constants from '../../../common/Constants';
import Helpers from '../../../common/Helpers';
import Spinner from '../Spinner.vue';

export default {
    name:'PayDirect',
    components:{
        Spinner
    },
    props:{
        visible: {
            type: Boolean,
            required: true,
        },
    },
    computed:{
        ...mapGetters(['dict','order','config','current_video']),
        priceTxtFortnight: function(){
          return Helpers.priceText(this.config.vip_price_fortnight);
        },
        priceTxtMonthly: function(){
            return Helpers.priceText(this.config.vip_price);
        },
        priceTxtSeason: function(){
            return Helpers.priceText(this.config.vip_price * 3 * (1-this.config.discount_season));
        },
        priceTxtAnnual: function(){
            return Helpers.priceText(this.config.vip_price * 12 * (1-this.config.discount_annual));
        },
        vipOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.VIP;
        },
        collectionOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.COLLECTION;
        },
        customVideoOrder: function(){
            return this.order.type === Constants.ORDER.TYPE.CUSTOM_VIDEO;
        },
        packageTypeOrder: function(){
            // 如果是充值卡类型的订单
            return this.order.type === Constants.ORDER.TYPE.PACKAGE;
        },
        priceText: function(){
            return Helpers.priceText(this.order.price, false);
        },
        collectionPriceTxt: function(){
            return Helpers.priceText(this.order.price);
        },
        customVtxt:function(){
          return Helpers.priceText(this.order.price, true);
        }
    },
    data(){
        return {
            quantity: '1',
            paymentStarted: false, // 标识支付是否已经发起
            activating: false,
            wait: false,
            packageMultiplex:[ // Package所需的
              1,2,3,4,7,10
            ],
        }
    },
    methods:{
        ...mapMutations(['Notify']),
        ...mapActions(['SubmitToCreateOrder','ActivateMyVip']),
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
        getPayUrl: function(e){
            e.preventDefault();
            if(!this.wait){
                this.wait = true;
                this.SubmitToCreateOrder(this.quantity).then(res => {
                    // 返回了2个数据，一个是支付页面的后缀，一个是订单号
                    // window.open(this.config.payment_url + res.s + '/' + res.id, '_blank');
                    window.location.href = res.re;
                    this.paymentStarted = true;
                }).catch(err => {
                    const msg = Helpers.errorNotification(err + ': '+this.dict.direct_pay_error);
                    this.Notify(msg);
                }).finally(() => {
                    setTimeout(()=>{
                        this.wait = false;
                    }, 2200);
                });
            }
        },
        manuallyActivate: function(e){
            e.preventDefault();
            if(!this.activating){
                this.activating = true;
                this.ActivateMyVip().then(() => {
                    const msg = Helpers.successNotification(this.dict.txt_activate_success);
                    this.Notify(msg);
                }).catch(err => {
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                }).finally(()=>{
                    this.activating = false;
                });
            }
        },
        calculatePackagePrice: function (multi){
            const p = multi * this.order.price;
            return Helpers.packagePriceText(multi, p);
        }
    }
}
</script>
<style scoped>

</style>