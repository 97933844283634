<template>
    <div class="queen mb-1" @click="qClicked">
        <img :src="defaultAvatar" class="w-20 h-20 rounded-lg">
        <h3 class="text-red-500 text-base text-center py-1">{{ queen.name }}</h3>
        <div class="flex justify-center py-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
            <span class="text-white">{{ queen.level }}</span>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name: 'QueenCard',
    props:{
        queen:{
            type: Object,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['config']),
        defaultAvatar: function(){
            return this.config.static_base + this.queen.images[0];
            // return this.config.static_base + this.queen.images[0].replace('_s.','_b.');
        }
    },
    methods:{
        qClicked: function(){
            this.$emit('queen-clicked',{queen: this.queen});
        }
    }
}
</script>
<style scoped>

</style>