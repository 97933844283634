<template>
  <div class="application bg-black">
    <div class="bg-gray-50 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {{ dict.txt_cooperate }}
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
                <div class="mt-1">
                    <select v-model="role" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option v-for="(r,i) in roles" :value="i" :key="i+'r'">{{ r }}</option>
                    </select>
                    <p class="mt-2 text-sm text-gray-500">
                        什么是 <span @click="showTips" class="text-blue-500">{{ currentRoleTxt() }}?</span>
                    </p>
                </div>

                <div class="mt-1" v-if="role===0">
                    <select v-model="type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option v-for="(t,i) in types" :value="i" :key="i+'t'">{{ t }}</option>
                    </select>
                    <p class="mt-2 text-sm text-gray-500">什么是 <span @click="showTips" class="text-blue-500">{{ currentTypeTxt() }}?</span></p>
                </div>

                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ dict.txt_my_contact }} <span class="text-red-500">({{ dict.txt_required }})</span>
                    </label>
                    <div class="mt-1">
                        <textarea v-model="contact" :placeholder="dict.txt_my_contact_placeholder" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">{{ dict.txt_my_contact_notes }}</p>
                </div>
                <div>
                    <div class="mt-1">
                        <textarea v-model="note" :placeholder="dict.txt_optional+':'+dict.txt_note" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
                    </div>
                </div>

                <div>
                    <button v-if="!submitDone" @click="onSubmit($event)" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ dict.txt_btn_submit }}
                        <Spinner :visible="waiting"></Spinner>
                    </button>
                    <p v-else class="text-green-600">
                        {{ dict.txt_enquire_ok }}
                    </p>
                </div>
            </form>
            </div>
        </div>
    </div>

    <div v-if="helperVisible" class="fixed inset-0 overflow-hidden z-50" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
        <div class="relative w-96">
            <div class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
            
            </div>
            <div class="h-full bg-white p-8 overflow-y-auto">
            <div class="pb-16 space-y-5">
                <div>
                <div class="flex items-start justify-between">
                    <div>
                    <h2 class="text-lg font-medium text-gray-900">
                        {{ currentRoleTxt() }}
                    </h2>
                    </div>
                    <button @click="closeHelper($event)" type="button" class="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
                </div>
                <div>
                <div class="flex items-center justify-between">
                    <p class="text-sm text-gray-500">
                        指共享自己的资源, 并提交给字母圈进行在线销售的同好. 注意，代售的资源必须是字母圈网站没有的, 已经发布的和已经代售的资源将不被系统接受, <span class="text-red-600">因此您申请要尽早哦！</span>在资源售出之后, 如果您选择:
                    </p>
                </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <p class="text-sm"><b class="text-blue-600">自己收款/自己交付产品</b>: 
                            表示字母圈仅仅在您的资源中显示您预留的联系信息, 需要您自己完成收款与交付的工作. 字母圈网站仅收取$59澳元/年的服务费用.</p>
                        </li>
                    
                        <li>
                            <p class="text-sm mt-2">
                                <b class="text-blue-600">由字母圈收款并交付产品</b>: 
                                表示您共享的资源, 由我们帮您完成所有的付款和支付流程, 并每2周和您根据实际销量与您现金结算(微信/支付宝). 字母圈网站仅收取$39澳元/年的服务费用. 结算规则如下:
                            </p>
                        </li>
                    </ul>
                </div>
                <div>
                <p class="text-sm text-gray-900">以售价人民币10元的资源为例</p>
                <dl class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <div class="py-1 flex justify-between text-sm font-medium">
                    <dt class="text-gray-500">支付平台收费</dt>
                    <dd class="text-gray-900">1.5元</dd>
                    </div>
                    <div class="py-1 flex justify-between text-sm font-medium">
                    <dt class="text-gray-500">营业税</dt>
                    <dd class="text-gray-900">2元</dd>
                    </div>
                    <div class="py-1 flex justify-between text-sm font-medium">
                    <dt class="text-gray-500">换汇成本</dt>
                    <dd class="text-gray-900">0.5元</dd>
                    </div>
                    <div class="py-1 flex justify-between text-sm font-medium">
                    <dt class="text-gray-500">你的收益</dt>
                    <dd class="text-gray-900">6元</dd>
                    </div>
                </dl>
                </div>
                <div>
                <div class="mt-1 flex items-center justify-between">
                    <p class="text-sm text-gray-500 italic">
                        注意: 您代售的资源需预先提交给字母圈审核后才能上线销售, 以防止发生纠纷是我们可以交付给买家. 同时, 您的资源不能涉及未成年人, 并不得构成任意形式的侵权, 字母圈有权拒绝和下线您的资源.
                    </p>
                </div>
                </div>
                <div class="flex">
                <button @click="closeHelper($event)" type="button" class="flex-1 bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none">
                    我知道了
                </button>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>

    <div v-if="adsVisible" class="fixed inset-0 overflow-hidden z-50" role="dialog" aria-modal="true">
        <div class="absolute inset-0 overflow-hidden">
            <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="relative w-96">
                <div class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                
                </div>
                <div class="h-full bg-white p-8 overflow-y-auto">
                <div class="pb-16 space-y-5">
                    <div>
                    <div class="flex items-start justify-between">
                        <div>
                        <h2 class="text-lg font-medium text-gray-900">
                            {{ currentRoleTxt() }}
                        </h2>
                        </div>
                        <button @click="closeHelper($event)" type="button" class="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <p class="text-sm text-gray-500">
                            字母圈的发展需要您的帮助, 适当的广告收入将用来收购更多的资源与提升系统的容量, 给开发团队多添一杯开发或一只鸡腿 :) 如果您认为有适合的广告商家愿意在我们这里投入广告, 我们将与您进行收入分成, 规则如下:
                        </p>
                    </div>
                    
                    <div>
                        <p class="text-sm text-gray-900">广告报价与分成比例</p>
                        <dl class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                            <div class="py-1 flex justify-between text-sm font-medium">
                            <dt class="text-gray-500">页顶(200澳元/周)</dt>
                            <dd class="text-gray-900">40%</dd>
                            </div>
                            <div class="py-1 flex justify-between text-sm font-medium">
                            <dt class="text-gray-500">页脚(100澳元/周)</dt>
                            <dd class="text-gray-900">30%</dd>
                            </div>
                            <div class="py-1 flex justify-between text-sm font-medium">
                            <dt class="text-gray-500">视频播放页(100澳元/周)</dt>
                            <dd class="text-gray-900">30%</dd>
                            </div>
                        </dl>
                    </div>

                    <div>
                        <div class="mt-1 flex items-center justify-between">
                            <p class="text-sm text-gray-500 italic">
                                注意: 为了不影响同好的观看体验, 一些很low的广告就不要介绍了. 不接受博彩类的合作.
                            </p>
                        </div>
                    </div>

                    <div class="flex">
                        <button @click="closeHelper($event)" type="button" class="flex-1 bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none">
                            我知道了
                        </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>

  </div>
</template>
<script>
import {mapGetters,mapActions,mapMutations} from 'vuex';
import Spinner from '../components/Widgets/Spinner.vue';
// import Constants from '../common/Constants';
import Helpers from '../common/Helpers';
export default {
    name: 'Application',
    components:{
        Spinner
    },
    watch:{
        'role': function(v){
            if(this.adsHelperShowed === 0 && v === 1){
                this.showTips();
            }
        }
    },
    computed:{
        ...mapGetters(['config','user','dict']),
        types: function(){
            return [
                this.dict.txt_seller_type1, this.dict.txt_seller_type2
            ];
        },
        // 申请类型 代售/代购/广告代理
        roles: function(){
            return [this.dict.txt_seller,this.dict.txt_ad_agent];
        },
    },
    data(){
        return {
            type: 0, //,
            role: 0, // 申请类型 代售/代购
            contact: '', // 申请类型 代售/代购
            note: '', // 申请类型 代售/代购
            waiting: false,
            submitDone: false,
            helperVisible: false,
            adsVisible: false,
            adsHelperShowed: 0,
        }
    },
    methods:{
        ...mapActions(['SendEnquiry']),
        ...mapMutations(['Notify']),
        onSubmit: function(e){
            e.preventDefault();
            // Validating
            if(this.contact.trim() === ''){
                const msg = Helpers.errorNotification('联系信息是必填项');
                this.Notify(msg);
                return;
            }
            if(!this.waiting){
                this.waiting = true;
                this.SendEnquiry({
                    type:this.type, role: this.role, contact: this.contact, note:this.note
                }).then(()=>{
                    // All good
                    this.submitDone = true;
                }).catch(err => {
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                }).finally(()=>{
                    setTimeout(()=>{
                        this.waiting = false;
                    }, 1500);
                })
            }
        },
        showTips: function(){
            if(this.role === 0){
                // 代售
                this.helperVisible = true;
                this.adsVisible = false;
            }else{
                this.helperVisible = false;
                this.adsVisible = true;
                this.adsHelperShowed++;
            }
        },
        currentRoleTxt: function(){
            return this.roles[this.role];
        },
        currentTypeTxt: function(){
            return this.types[this.type];
        },
        closeHelper: function(e){
            e.preventDefault();
            this.helperVisible = false;
            this.adsVisible = false;
        }
    }
}
</script>