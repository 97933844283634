<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
  <div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0" aria-hidden="true"></div>

    <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
      <div class="w-screen max-w-md">
        <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
          <div class="flex-1 h-0 overflow-y-auto">
            <div class="py-6 px-4 bg-gray-800 sm:px-6">
              <div class="flex items-center justify-between">
                <h2 class="text-lg font-medium text-white" id="slide-over-title">
                  {{ dict.daigo_widget_title }}
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="px-4 divide-y divide-gray-200 sm:px-6">
                <div class="space-y-3 pt-6 pb-5">
                    <p class="text-sm leading-6">{{ dict.daigo_widget_intro1 }}</p>
                    <p class="mt-0 text-sm text-red-600">
                        {{ dict.daigo_widget_intro6 }} <a class="text-blue-600" :href="'mailto:'+mailLink">{{ mailLink }}</a>
                    </p>
                    <p class="mt-0 text-sm text-red-600">{{ dict.daigo_widget_intro2 }}</p>
                    <p class="mt-0 text-sm text-red-600">{{ dict.daigo_widget_intro3 }}</p>
                    <p class="mt-0 text-sm text-red-600">{{ dict.daigo_widget_intro4 }}</p>
                    <p class="mt-0 text-sm text-red-600">{{ dict.daigo_widget_intro5 }}</p>
                    <p class="mt-0 text-sm text-red-600">{{ dict.daigo_widget_intro7 }}</p>
                    <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                        <input id="agr" aria-describedby="comments-description" v-model="agreed" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                        <label for="agr" class="font-medium text-gray-700">{{ dict.daigo_widget_agree_text }}</label>
                        </div>
                    </div>
                    <p class="text-sm leading-6">
                        <button :disabled="!agreed" @click="contact($event)" :class="agreed ? 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500': 'bg-gray-300'"
                        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >{{ dict.daigo_widget_btn_txt }}</button>
                    </p>
                </div>
                <div v-if="current_lang!=='cn'">
                  <img src="line.jpeg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 px-4 py-4 flex justify-end">
            <span>
                <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ dict.GoBack }}
                </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name: 'Daigo',
    props:{
        visible:{
            type: Boolean,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['dict','config','current_lang']),
        mailLink: function(){
            return this.config.qq + '@gmail.com';
        }
    },
    data(){
        return {
            agreed: false,
        }
    },
    methods:{
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
        contact: function(e){
            e.preventDefault();
            if(this.agreed){
                window.open('mailto:'+this.mailLink, '_blank');
            }
        }
    }
}
</script>
<style scoped>

</style>