<template>
    <div class="float-left">
        <span class="text-sm leading-6 mr-3" :class="isQueen?'text-yellow-500':'text-red-500'">
            <a @click="onClicked(c,$event)" href="#">#{{ c.n }}</a>
        </span>
    </div>
</template>
<script>
export default {
    name: "Category",
    props:{
        c:{
            type: Object,
            required: true,
        }
    },
    computed:{
        isQueen: function(){
            return this.c.q===1;
        }
    },
    methods:{
        onClicked: function(c, e){
            e.preventDefault();
            this.$emit('on-clicked',c);
        }
    }
}
</script>
<style scoped>

</style>