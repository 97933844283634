<template>
    <div class="fixed inset-0 overflow-hidden z-40" role="dialog" aria-modal="true" v-if="visible">
        <div class="absolute inset-0 overflow-hidden">
            <div class="absolute inset-0" aria-hidden="true"></div>
            <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <div class="w-screen max-w-md bg-gray-800">
                <div class="h-full flex flex-col shadow-xl overflow-y-scroll">
                <div class="px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-white">
                        {{ dict.txt_profile }}
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                        <button class="bg-red-500 rounded-md text-white hover:text-gray-500 focus:ring-2 focus:ring-indigo-500" @click="close($event)">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                    </div>
                </div>

                <div>
                    <div class="pb-1 sm:pb-6 mb-2">
                        <div>
                            <div class="relative h-64 sm:h-64">
                            <img class="absolute h-full w-full object-cover" :src="bigAvatar" alt="">
                            </div>
                            <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                                <div class="sm:flex-1">
                                    <div>
                                        <div class="flex items-center">
                                            <h3 class="font-bold text-xl text-red-500 sm:text-2xl">{{ queen.name }}</h3>
                                        </div>
                                        <p class="text-sm text-gray-500">同好评分: <b class="text-red-500">{{ queen.total }}</b>, 视频资源: <b class="text-red-500">{{ queen.videos_count }}</b></p>
                                    </div>
                                    <div class="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3" style="display:none;">
                                        <button type="button" class="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1">
                                            发表评论
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pb-1 sm:pb-6 border-t border-gray-700 m-1" v-if="latestVideos.length > 0">
                        <p class="text-red-500 text-base text-center m-2">{{ dict.txt_q_playback }}</p>
                        <div v-for="(v,i) in latestVideos" :key="i+'lv'" class="bg-black px-3 py-2 m-3 rounded-md" @click="select(v)">
                            <p class="text-white text-sm">{{ v.t}}: {{v.d}}</p>
                        </div>
                        <div class="m-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                            <button @click="viewAll" type="button" class="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1">
                                <span class="text-red-500">{{ queen.name }}</span> {{ dict.txt_q_all_v }}
                            </button>
                        </div>
                    </div>

                    <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0 border-t border-gray-700 m-1">
                        <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                            <div>
                                <dt class="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                                    {{ dict.txt_q_appraisal }}
                                </dt>
                                <dd class="mt-1 text-sm">
                                    <div class="flex justify-around">
                                        <div>
                                            <p class="text-3xl text-white p-3 text-center">{{ queen.feet }}</p>
                                            <p class="text-sm text-gray-400 text-center">{{ dict.txt_q_feet }}</p>
                                        </div>
                                        <div>
                                            <p class="text-3xl text-white p-3 text-center">{{ queen.looking }}</p>
                                            <p class="text-sm text-gray-400 text-center">{{ dict.txt_q_looking }}</p>
                                        </div>
                                        <div>
                                            <p class="text-3xl text-white p-3 text-center">{{ queen.shape }}</p>
                                            <p class="text-sm text-gray-400 text-center">{{ dict.txt_q_shape }}</p>
                                        </div>
                                        <div>
                                            <p class="text-3xl text-white p-3 text-center">{{ queen.pro }}</p>
                                            <p class="text-sm text-gray-400 text-center">{{ dict.txt_q_pro }}</p>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
export default {
    name:'QueenDetail',
    props:{
        visible:{
            type: Boolean,
            required: true,
        },
        queen:{
            type: Object,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['config','dict','user']),
        bigAvatar: function(){
            return this.config.static_base + this.queen.images[0].replace('_s.','_b.');
        }
    },
    watch:{
        'visible': function(v){
            if(v){
                this.LoadQueenLatestVideos(this.queen).then(res => {
                    this.latestVideos = res.videos;
                    this.fans = res.fans;
                    this.like = res.like;
                })
            }else{
                this.latestVideos = [];
            }
        }
    },
    data(){
        return {
            latestVideos:[],
            fans:0,
            like:0,
        }
    },
    mounted(){
        // console.log('queen detail mounted', this.queen.id);
    },
    methods:{
        ...mapActions(['LoadQueenLatestVideos','LoadVideo']),
        close: function(e){
            e.preventDefault();
            this.$emit('closed');
        },
        select: function(v){
            if(this.user.id !== null){
                this.LoadVideo(v.i).then(()=>{
                    this.$emit('video-selected', {video:v});
                });
            }
            else{
                // 用户没有登陆
                this.$router.push({
                    name:'SignIn'
                });
            }
        },
        viewAll: function(){
            this.$router.push({
                name:'Search',params:{keyword: this.queen.name}
            });
        }
    }
}
</script>