<template>
  <div class="bg-black flex flex-col py-2 sm:px-6 lg:px-8 h-screen">
    <div class="py-4 flex flex-wrap justify-center space-x-6">
        <router-link to="/myspace" class="text-base font-medium text-white hover:text-red-500" key="MySpace">
            我的视频
        </router-link>
        <a href="#" class="text-base font-medium text-white hover:text-red-500 border-b">
            我的收藏
        </a>
        <a href="#" @click="showUpdatePwd($event)" class="text-base font-medium text-yellow-400 hover:text-red-500">
            {{ updatePwdVisible ? dict.txt_cancel : dict.txt_update_pwd }}
        </a>
    </div>
    <div class="grid grid-cols-1">
        <div class="mb-2 pl-2">
            <div v-if="updatePwdVisible" class="mr-1">
                <UpdatePwdForm :dict="dict" @pwd-updated="pwdUpdated"></UpdatePwdForm>
            </div>
        </div>
        <div class="md:col-span-5" v-if="ready">
            <Videos @video-selected="onVideoSelected" :type="type" :has-more="true"></Videos>
        </div>
    </div>
  </div>
</template>
<script>
import Constants from "../common/Constants";
import {mapGetters,mapMutations} from 'vuex';
import Videos from "../components/Videos.vue";
import UpdatePwdForm from "../components/User/UpdatePwdForm.vue";

export default {
    name: 'MyFavorite',
    components:{
        Videos,UpdatePwdForm
    },
    computed:{
        ...mapGetters(['dict','user']),
    },
    data(){
        return {
            updatePwdVisible: false,
            newPwd:'',
            newPwdRe:'',
            myVideos:[],
            ready: false,
            type: Constants.LIST_TYPE.MY_FAVORITE,
        }
    },
    created(){
        setTimeout(()=>{
            this.ready = true;
        },300);
    },
    mounted(){
        this.$nextTick(()=>{
            this.UpdateCurrentPageVideosType(this.type); // 更新一下当前的视频类型
        })
    },
    methods:{
        ...mapMutations(['UpdateCurrentPageVideosType']),
        onVideoSelected: function(payload){
            if(payload.loggedIn){
                this.$router.push({
                    name:'Watch',params:{id: payload.video.i}
                });
            }else{
                this.$router.push({
                    path:'/sign-in'
                });
            }
        },
        showUpdatePwd: function(e){
            e.preventDefault();
            this.updatePwdVisible = !this.updatePwdVisible;
        },
        pwdUpdated: function(){
            this.updatePwdVisible = false;
        }
    }
}
</script>