<template>
    <div class="bg-black flex flex-col py-2 sm:px-6 lg:px-8 h-screen">
        <ul role="list" class="bg-black grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <li v-for="(prod, idx) in products" :key="idx" class="col-span-1 flex flex-col text-center bg-gray-800 m-4 rounded-lg shadow divide-y divide-gray-700">
                <ProductCard
                    :product="prod"
                    :base="config.static_base + '/'"
                    @on-selected="showProductDetail"
                ></ProductCard>
            </li>
        </ul>

        <!-- 合集详情 -->
        <div class="fixed inset-0 overflow-hidden z-40" v-if="productDetailVisible">
        <div class="absolute inset-0 overflow-hidden">
            <div class="absolute inset-0" aria-hidden="true"></div>

            <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <div class="w-screen max-w-md">
                <div class="h-full flex flex-col bg-gray-800 shadow-xl overflow-y-scroll">
                <div class="p-6">
                    <div class="flex items-start justify-between">
                      <h2 class="text-lg font-medium text-red-500" id="slide-over-title" style="max-width: 60%;">
                          {{ current_lang === 'cn' ? lastShownProduct.name : lastShownProduct.name_en }}
                      </h2>
                      <div class="ml-3 h-7 flex items-center" v-if="hasEnoughCoins">
                          <button @click="redeemNow($event, lastShownProduct)" class="bg-blue-500 rounded-md text-white px-4 mr-4">
                              兑换
                          </button>
                          <button @click="hideProductDetail($event)" class="bg-red-500 rounded-md text-white hover:text-red-600">
                          <span class="sr-only">Close panel</span>
                          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                          </button>
                      </div>
                      <div class="ml-3 h-7 flex items-center" v-else>
                          <button @click="buyNow($event, lastShownProduct)" class="bg-blue-500 rounded-md text-white px-4 mr-4">
                              购买
                          </button>
                          <button @click="hideProductDetail($event)" class="bg-red-500 rounded-md text-white hover:text-red-600">
                          <span class="sr-only">Close panel</span>
                          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                          </button>
                      </div>
                    </div>
                </div>
                
                <ul class="flex-1 divide-y divide-gray-600 overflow-y-auto">
                    <li v-for="(v, vIdx) in productVideos" :key="vIdx">
                    <div class="relative group py-6 px-5 flex items-center">
                        <a href="#" class="-m-1 flex-1 block p-1" @click="playVideo($event, v)">
                        <div class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"></div>
                        <div class="flex-1 flex items-center min-w-0 relative">
                            <span class="flex-shrink-0 inline-block relative">
                            <img class="h-16 rounded-md shadow-2xl" :src="videoThumb(v)">
                            </span>
                            <div class="ml-4 truncate">
                            <p class="text-sm font-medium text-white truncate" :style="current_lang!=='cn'?'white-space: break-spaces;':null" v-html="v.t"></p>
                            <p class="text-sm text-gray-500 truncate">{{dict.duration}}: {{ v.d }}</p>
                            </div>
                        </div>
                        </a>
                    </div>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>

        <CheckoutBlock
            :start="startCheckout"
            @checkout-done="checkoutDone"
            @checkout-cancelled="checkoutCancelled"
        ></CheckoutBlock>
    </div>
</template>
<script>
import Helpers from '../common/Helpers';
import Constants from '../common/Constants';
import {mapGetters, mapActions, mapMutations} from 'vuex';
import ProductCard from '../components/Widgets/ProductCard.vue';
import CheckoutBlock from '../components/Widgets/CheckoutBlock.vue';

export default {
    name:'Collection',
    components:{
        ProductCard,CheckoutBlock
    },
    computed:{
        ...mapGetters(['products','config','current_lang','dict','order','user']),
      hasEnoughCoins: function(){
          if (this.lastShownProduct!==null){
            const pInCoins = this.lastShownProduct.price_aud*5*15/20;
            return this.user.savings >= pInCoins;
          }
          return false;
      }
    },
    data(){
        return {
            pageNumber:1,
            total: 0,
            productVideos:[],
            productDetailVisible: false,
            lastShownProductId: 0,
            lastShownProduct: null,
            startCheckout: false,
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        ...mapActions(['LoadCollection','LoadCollectionVideo','redeemCollection']),
        ...mapMutations(['Notify','UpdateOrder']),
        videoThumb: function(v){
            const s = v.url.substring(0, v.url.length - 41);
            return this.config.static_base + '/' + s + v.i + 'thumb_0001.jpg';
        },
        fetch: function(){
            this.LoadCollection(this.pageNumber);
        },
        showProductDetail: function(payload){
            this.productDetailVisible = true;
            if(this.lastShownProductId !== payload.product.id){
                // 表示加载一个新的没有显示过的合集
                this.productVideos = [];
                this.lastShownProductId = payload.product.id;
                this.lastShownProduct = payload.product;
                this.LoadCollectionVideo(payload.product.id).then(res => {
                    this.productVideos = res.videos;
                }).catch(err => {
                    this.lastShownProductId = 0;
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                });
            }
        },
        hideProductDetail: function(e){
            e.preventDefault();
            this.productDetailVisible = false;
        },
        // 当用户的点数超过合集所要求的点数时，可以直接兑换
        redeemNow: function(e, product){
          e.preventDefault();
          this.redeemCollection(product.id)
            .then(() => {
              // 购买成功
              const msg = Helpers.successNotification(this.dict.msg_redeem_ok2);
              this.productDetailVisible = false;
              this.Notify(msg);
            })
            .then(err => {
              const msg = Helpers.errorNotification(err);
              this.Notify(msg);
            })
        },
        buyNow: function(e, product){
            e.preventDefault();
            // 生成一个订单
            const tempOrder = {
                type: Constants.ORDER.TYPE.COLLECTION,
                price: product.price_aud*100,
                productId: product.id,
                payment_method: null,
            };
            this.UpdateOrder(tempOrder);
            this.startCheckout = true;
            this.productDetailVisible = false;
        },
        checkoutCancelled: function(){
            this.startCheckout = false;
        },
        checkoutDone: function(){
            this.startCheckout = false;
        },
        playVideo: function(e,v){
            e.preventDefault();
            this.startCheckout = false;
            if(this.user.id !== null){
                this.$router.push({
                    name:'Watch',params:{id: v.i}
                });
            }
            else{
                this.$router.push({
                    path:'/sign-in'
                });
            }
        }
    }
}
</script>