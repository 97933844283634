<template>
  <div class="ml-3 inline-flex rounded-md shadow mt-3">
      <a @click="loadPrev($event)" class="mr-4 inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50">
        {{ dict.prev_page }}
      </a>
      <a @click="loadMore($event)" class="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50">
        {{ dict.next_page }}
      </a>
    <p v-if="loading" class="text-white p-2">{{ dict.loading_video }}</p>
  </div>
</template>

<script>
import {mapGetters,mapActions,mapMutations} from "vuex";
import Helpers from "@/common/Helpers";

export default {
  name: "LoadMoreBtn",
  props:{
    type:{
      type: Number,
      required: true
    }
  },
  computed:{
    ...mapGetters(['dict','pages_count']),
  },
  data(){
    return {
      loading: false,
      currentPage:1,
    }
  },
  methods:{
    ...mapActions(['FetchVideos']),
    ...mapMutations(['Notify']),
    loadMore: function(){
      /**
       * 对于加载更多功能来讲, 这是要向用户展示合集的资源, 因此需要加载全部
       * 但是全部的话，可能会导致被攻击,因此加载全部，最多加载 50 部应该就够了
       * 因此传递给action的参数，需要提供的是 type, pageNumber, pageSize
       */
      if(this.currentPage>=this.pages_count){
        const msg = Helpers.successNotification('没有更多了');
        this.Notify(msg);
        return;
      }
      this.currentPage++;
      this.loading = true;
      this.FetchVideos({
        type:this.type,pageNumber: this.currentPage
      }).then(()=>{
        this.loading = false;
      }).catch(()=>{
        this.loading = false;
      })
    },
    loadPrev: function(){
      if (this.currentPage > 1){
        this.loading = true;
        this.currentPage--;
        this.FetchVideos({
          type:this.type,pageNumber: this.currentPage
        }).then(()=>{
          this.loading = false;
        }).catch(()=>{
          this.loading = false;
        })
      }
      else{
        const msg = Helpers.successNotification('没有更多了');
        this.Notify(msg);
      }
    }
  }
}
</script>

<style scoped>

</style>