<template>
    <header class="bg-black fixed w-full z-30 border-0 border-b border-highlight">
        <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <!-- 针对桌面版的导航菜单 -->
            <div class="hidden w-full py-6 md:flex items-center justify-between border-b border-gray-900 lg:border-none">
                <div class="flex items-center">
                    <router-link to="/" class="flex" key="Home">
                        <img class="h-10 w-auto" src="zm_logo.png">
                        <div v-if="IsVipNow" class="ml-2 mt-2 p-1 text-sm h-7 bg-yellow-400 text-white rounded-lg">VIP {{ user.vip_expired_at.substring(0,10) }}</div>
                    </router-link>
                    <div class="hidden ml-10 space-x-8 lg:block">
                        <button @click="gotoCheckoutPage($event)" v-if="!IsVipNow && loggedIn" class="ml-2 mt-1 p-1 text-sm h-7 bg-yellow-500 text-white rounded-lg">
                            {{ dict.txt_buy }} VIP
                        </button>
                        <router-link to="/" class="text-base font-medium text-white hover:text-red-500" key="Home1">{{ dict.home }}</router-link>
                        <router-link to="/custom" class="text-base font-medium text-white hover:text-red-500" key="Custom">{{ dict.custom }}</router-link>
                        <router-link v-if="current_lang==='cn'" to="/retrieve" class="text-base font-medium text-yellow-500 hover:text-red-500" key="Retrieve">私有视频收购</router-link>
                        <router-link to="/collection" class="text-base font-medium text-white hover:text-red-500" key="Collection">
                            {{ dict.txt_collection }}
                        </router-link>
                        
                        <router-link to="/mistress" class="text-base font-medium text-white hover:text-red-500" key="Mistress">
                            {{ dict.txt_mistress }}
                        </router-link>

                        <router-link to="/myspace" class="text-base font-medium text-white hover:text-red-500" key="MySpace">
                            {{ dict.my_space }}<span v-if="isVip" class="font-medium text-yellow-400">(VIP)</span>
                        </router-link>

                        <router-link to="/search/0" class="text-base font-medium text-white hover:text-red-500" key="Search">
                            {{ dict.txt_Search }}
                        </router-link>

                        <router-link
                            v-if="!loggedIn"
                            to="/sign-in"
                            class="inline-block bg-indigo-500 py-2 px-2 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
                        >{{ dict.sign_in }}</router-link>

                        <router-link
                            v-if="!loggedIn"
                            to="/sign-up"
                            class="inline-block bg-white py-2 px-2 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
                        >{{ dict.sign_up }}</router-link>

                        <a v-if="loggedIn" @click="logout" class="inline-block bg-red-500 py-2 px-2 border border-transparent rounded-md text-base font-medium text-white hover:bg-red-800">{{ dict.sign_out }}</a>
                        <a href="#" @click="switch_lang" class="inline-block bg-green-500 py-2 px-2 border border-transparent rounded-md text-base font-medium text-white hover:bg-green-800">{{ dict.switch_lang }}</a>
                    </div>
                </div>
            </div>

            <!-- 针对移动版的导航菜单 -->
            <div class="md:hidden w-full py-2 flex items-center justify-between">
                <!-- 显示子菜单的按钮 -->
                <a href="#" class="text-white" @click="toggleSubmenu($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </a>
                <!-- 首页链接 Logo -->
                <router-link to="/" class="text-white" key="Home">
                    <div class="flex">
                        <img class="h-12 w-auto" src="zm_logo.png">
                        <div v-if="IsVipNow" class="ml-2 mt-3 p-1 text-sm h-7 bg-yellow-400 text-white rounded-lg">
                            VIP {{ user.vip_expired_at.substring(0,10) }}
                            <span v-if="user.savings>0">,有{{ user.savings }}币</span>
                        </div>
                        <div v-else class="ml-2 mt-3 p-1 text-sm h-7 bg-red-500 text-white rounded-lg">
                            <span class="line-through">非VIP</span> 有{{ user.savings }}币
                        </div>
                    </div>
                </router-link>
                <button @click="gotoCheckoutPage($event)" v-if="loggedIn" class="ml-2 mt-1 p-1 text-sm h-7 bg-blue-400 text-white rounded-lg">
                    {{ dict.txt_charge }}
                </button>
                
                <!-- 搜索链接 -->
                <router-link to="/search/0" class="text-base font-medium text-white hover:text-red-500" key="Search">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </router-link>
            </div>

            <div class="fixed inset-0 overflow-hidden" v-show="submenu_visible">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <div class="relative w-96">
                        <div class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"></div>
                        <div class="h-full bg-gray-900 p-8 overflow-y-auto">
                            <div class="pb-16 space-y-6">
                                <div>
                                    <div class="mt-0 flex items-start justify-between">
                                        <div :class="loggedIn ? '-mt-2':null">
                                            <h2 class="text-lg font-medium text-red-500">
                                                <span class="text-white">{{ userName }}</span>@{{ dict.txt_brand }}
                                            </h2>
                                            <p v-if="userEmail" class="text-white text-sm">Email: {{ userEmail }}</p>
                                        </div>
                                        <button @click="toggleSubmenu($event)" type="button" class="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-red-500 hover:bg-red-500 hover:text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <dl class="mt-2 border-t border-gray-700 divide-y divide-gray-700">
                                        <router-link to="/" class="text-base font-medium" key="Home">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">首页</dt>
                                                <dd class="text-white">Home</dd>
                                            </div>
                                        </router-link>
                                        <router-link to="/custom" class="text-base font-medium" key="Custom">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">订制/代售视频</dt>
                                                <dd class="text-white">Custom Videos</dd>
                                            </div>
                                        </router-link>
                                        <router-link to="/collection" class="text-base font-medium" key="Collection">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">精选集</dt>
                                                <dd class="text-white">Albums</dd>
                                            </div>
                                        </router-link>
                                        <router-link to="/myspace" class="text-base font-medium" key="MySpace">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">
                                                    {{ dict.my_space }}<span v-if="isVip" class="font-medium text-yellow-400">(VIP)</span>
                                                </dt>
                                                <dd class="text-blue-500">
                                                    <a v-if="isVip" @click="useOld($event)" href="#" class="text-red-600">
                                                        使用旧版本
                                                    </a>
                                                </dd>
                                            </div>
                                        </router-link>
                                        <router-link to="/mistress" class="text-base font-medium" key="Mistress">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">女主天地</dt>
                                                <dd class="text-white">Asian Mistresses</dd>
                                            </div>
                                        </router-link>
                                        <router-link
                                            v-if="!loggedIn"
                                            to="/sign-in" class="text-base font-medium"
                                        >
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-red-500">登陆</dt>
                                                <dd class="text-red-500">Sign In</dd>
                                            </div>
                                        </router-link>

                                        <router-link
                                            v-if="!loggedIn"
                                            to="/sign-up" class="text-base font-medium"
                                        >
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-red-500">注册新用户</dt>
                                                <dd class="text-red-500">Sign Up</dd>
                                            </div>
                                        </router-link>

                                        <a v-if="loggedIn" @click="logout" class="text-base font-medium">
                                            <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                                                <dt class="text-white">退出登陆</dt>
                                                <dd class="text-white">Logout</dd>
                                            </div>
                                        </a>
                                    </dl>
                                </div>
                                <div class="relative flex">
                                    <span class="relative inline-flex rounded-md shadow-sm">
                                        <button @click="switch_lang" type="button" class="inline-flex items-center bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white">
                                            {{ dict.switch_lang }}
                                        </button>
                                        <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    </span>
                                    <button @click="contact($event)" type="button" class="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none">
                                        {{ dict.txt_contact_us }}
                                        <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                        </svg>
                                    </button>                                    
                                </div>
                                <div class="relative flex">
                                    <button @click="forgetThisDevice($event)" type="button" class="w-full inline-flex items-center px-4 py-2 border border-red-500 shadow-sm text-sm font-medium rounded-md text-gray-100 bg-red-600 focus:outline-none">
                                        {{ dict.txt_switch_device }}
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
                                        </svg>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <CheckoutBlock
                :start="startCheckout"
                @checkout-done="checkoutDone"
                @checkout-cancelled="checkoutCancelled"
            ></CheckoutBlock>
        </nav>

        
<div v-if="forgetWarningVisible" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <transition 
            name="custom-classes-transition" 
            enter-active-class="ease-out duration-300" 
            enter-class="opacity-0" 
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-to-class="opacity-0"
            >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    </transition>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <transition 
            name="custom-classes-transition" 
            enter-active-class="ease-out duration-300" 
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <!-- Heroicon name: outline/exclamation -->
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                {{ dict.txt_switch_device }}
            </h3>
            <div class="mt-2">
                <p class="text-sm text-gray-500">{{ dict.txt_switch_device_waring }}</p>
            </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button @click="confirmForget($event)" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
            {{ dict.txt_confirm_switch }}
            </button>
            <button @click="cancelForget($event)" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
            {{ dict.txt_keep_device }}
            </button>
        </div>
        </div>
    </transition>
  </div>
</div>

    </header>
</template>
<script>
import Helpers from '../common/Helpers';
import Constants from '../common/Constants';
import {mapGetters, mapMutations, mapActions} from 'vuex';
import CheckoutBlock from '../components/Widgets/CheckoutBlock.vue';

export default {
    name: 'NavBarNew',
    components:{
        CheckoutBlock
    },
    computed:{
        ...mapGetters(['current_lang','user','config','submenu_visible','IsVipNow','dict','order']),
        // 判定用户是否为已经登陆
        loggedIn: function(){
            return this.user.id !== null;
        },
        isVip: function(){
            return Helpers.isVip();
        },
        mailLink: function(){
            return this.config.qq + '@gmail.com';
        },
        userEmail: function(){
            return this.loggedIn && localStorage.email ? localStorage.email : false;
        },
        userName: function(){
            return this.user.name === null ? this.dict.txt_visitor : this.user.name;
        }
    },
    data(){
        return {
            submenuVisible: false,
            startCheckout: false,
            forgetWarningVisible: false,
        }
    },
    methods:{
        ...mapMutations(['SwitchLang','InitUser','UpdateSubmenuVisible','UpdateOrder','Notify']),
        ...mapActions(['SwitchDevice']),
        toggleSubmenu: function(e){
            e.preventDefault();
            this.UpdateSubmenuVisible(!this.submenu_visible);
        },
      /**
       *  切换语言
       */
      switch_lang: function(){
            if(this.current_lang === 'cn'){
                this.SwitchLang('en');
                this.$emit('lang-change',{lang:'en'});
                localStorage.lang = 'en';
            }else{
                this.SwitchLang('cn');
                this.$emit('lang-change',{lang:'cn'});
                localStorage.lang = 'cn';
            }
            this.UpdateSubmenuVisible(!this.submenu_visible);
        },
        logout: function(){
            this.InitUser({
                id: null,
                name: null,
                expired_at: null,
                vip_expired_at: '',
            });
            // 把本地保存的 local storage 清空
            localStorage.uid = ''; 
            localStorage.vip_expired_at = '';
            window.location.href = window.location.origin;
        },
        contact: function(e){
            e.preventDefault();
            window.open('mailto:'+this.mailLink, '_blank');
        },
        useOld: function(e){
            e.preventDefault();
            e.stopPropagation();
            window.open('https://cnfemdomtube.com','_blank');
        },
        gotoCheckoutPage: function(e){
            e.stopPropagation();
            const tempOrder = {
                type: Constants.ORDER.TYPE.VIP,
                price: this.config.vip_price,
                productId: '3a96c2c3-4624-47b6-a757-742958fcb60a',
                payment_method: null,
            };
            this.UpdateOrder(tempOrder);
            this.startCheckout = true;
        },
        checkoutCancelled: function(){
            this.startCheckout = false;
        },
        checkoutDone: function(){
            this.startCheckout = false;
        },
        forgetThisDevice: function(e){
            e.preventDefault();
            this.forgetWarningVisible = true;
        },
        cancelForget: function(e){
            e.preventDefault();
            this.forgetWarningVisible = false;
        },
        confirmForget: function(e){
            e.preventDefault();
            this.SwitchDevice().then(()=>{
                // 成功
                const msg = Helpers.successNotification('All Done! Goodbye.');
                this.Notify(msg);
                this.logout();
            }).then(err => {
                const msg = Helpers.errorNotification(err);
                this.Notify(msg);
            });
        }
    }
}
</script>
<style scoped>

</style>