<template>
    <div class="relative py-8 bg-white overflow-hidden">
        <div class="relative px-4 sm:px-6 lg:px-8">
            <div class="text-lg max-w-prose mx-auto">
            <h1>
                <span class="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    私有视频资源收购
                </span>
            </h1>
            <p class="mt-2 text-sm text-gray-700 leading-8">
                各位同好, 如果您手中有珍藏的私有视频资源, 并且是没有在本站发布的, 您可以联系我们进行出售, 以便可以共享给更多人.
            </p>
            <p class="mt-2 text-sm text-gray-700 leading-8">
                条件和步骤非常简单, 只需要写明您的资源是哪位S的, 内容简介, 几张截图, 同时注明您希望的出售价格, 发邮件到 <a class="text-blue-600" href="mailto:zimu7seven@gmail.com">zimu7seven@gmail.com</a>即可。
            </p>
            <p class="mt-2 text-sm text-gray-700 leading-8">
                我们会通过邮件和您沟通, 一旦成交, 您需要先把资源传给我们, 确认无误之后, 字母圈将通过支付宝即时付给您现金.
            </p>
            <p class="mt-2 text-sm text-red-700 leading-8">
                注意, 请您确认您的资源是站里还没有发布的。另外, 我们只接受本站VIP用户的出售申请; 同时字母圈拒绝并反对任何涉及种族歧视等内容的资源.
            </p>
            </div>
            <router-link to="/join" class="text-base font-medium" key="Application">
              <div class="py-3 flex justify-between text-sm font-medium border-b border-gray-700">
                <p class="text-blue-800">或者您也可以点击这里, 选择合作加盟</p>
              </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
  name: "Retrieve", // 回收同好视频的页面, 只对中文有效
};
</script>
