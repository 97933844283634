<template>
    <div :class="(pressing?'bg-gray-700 ':' ') + (nc)" class="flex flex-col px-4 py-1 text-white bbwrap" @click="clicked($event)">
        <div class="icon-slot" v-show="!spin">
            <slot></slot>
        </div>
        <div class="icon-slot pl-3">
            <Spinner :visible="spin"></Spinner>
        </div>
        <div class="item-txt mt-1">
            {{ title }}
        </div>
    </div>
</template>

<script>
import Spinner from './Spinner.vue'
export default {
    name: "NiceBtn",
    components:{
        Spinner
    },
    props:{
        title:{
            type: String,
            required: true
        },
        spin:{ // 是否点击后会旋转
            type: Boolean,
            required:false,
            default: false
        },
        nc:{
            type: String,
            required: false,
            default:''
        }
    },
    data(){
        return {
            pressing: false
        }
    },
    methods:{
        clicked: function(e){
            this.$emit('pressed',{event:e});
            this.pressing = true;
            setTimeout(()=>{
                this.pressing = false;
            },300)
        }
    }
}
</script>

<style scoped>
.bbwrap{
    width: 80px;
}
.icon-slot{
    display: flex;
    justify-content: center;;
}
.item-txt{
    text-align: center;
    font-weight: 100;
}
</style>
