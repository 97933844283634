<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
  <div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0" aria-hidden="true"></div>

    <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
      <div class="w-screen max-w-md">
        <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
          <div class="flex-1 h-0 overflow-y-auto">
            <div class="py-6 px-4 bg-gray-800 sm:px-6">
              <div class="flex items-center justify-between">
                <h2 class="text-lg font-medium text-white" id="slide-over-title">
                  {{ dict.redeem_widget_title }}
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="px-4 divide-y divide-gray-200 sm:px-6">
                <div class="space-y-6 pt-6 pb-5">
                    <p class="text-sm leading-6">
                        <span class="text-gray-800">{{ dict.redeem_subtitle }}</span> <a class="text-blue-600" :href="'mailto:'+mailLink">{{ mailLink }}</a>
                    </p>
                </div>

                <div class="space-y-6 pt-6 pb-5">
                    <button @click="buyCode($event)" type="button" class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ dict.btn_get_code }}
                    </button>
                </div>

                <div class="space-y-6 pt-6 pb-5">
                  <div>
                    <label for="project-name" class="block text-sm font-medium text-gray-900">
                      {{ dict.already_got_code }}?
                    </label>
                    <div class="mt-1">
                      <input type="text" v-model="code" :placeholder="dict.fill_in_code" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                    </div>
                    <div class="space-y-6 pt-6 pb-5">
                        <p class="text-sm leading-6">
                            <span class="text-gray-800">{{ dict.note_code_failed }}</span>
                        </p>
                        <p class="text-sm leading-6">
                            
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 px-4 py-4 flex justify-end">
            <span>
                <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ dict.btn_redeem_return }}
                </button>
            </span>
            <span class="relative inline-flex rounded-md shadow-sm">
                <button @click="redeem($event)" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <Spinner :visible="isRedeeming"></Spinner>
                    {{ dict.btn_redeem }}
                </button>
                <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import Constants from '../../../common/Constants';
import Helpers from '../../../common/Helpers';
import axios from 'axios';
import Spinner from '../Spinner.vue';
export default {
    name:'RedeemCode',
    components:{
        Spinner
    },
    props:{
        visible:{
            type: Boolean,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['order','config','dict','user','current_video']),
        isWechat: function(){
            return this.order.payment_method === Constants.ORDER.PAYMENT_METHOD.WECHT;
        },
        isAlipay: function(){
            return this.order.payment_method === Constants.ORDER.PAYMENT_METHOD.ALIPAY;
        },
        isCreditCard: function(){
            return this.order.payment_method === Constants.ORDER.PAYMENT_METHOD.CREDIT_CARD;
        },
        isCrypto: function(){
            return this.order.payment_method === Constants.ORDER.PAYMENT_METHOD.CRYPTO;
        },
        isOffline: function(){
            return this.order.payment_method === Constants.ORDER.PAYMENT_METHOD.OFFLINE;
        },
        mailLink: function(){
            return this.config.qq + '@gmail.com';
        }
    },
    data(){
        return {
            code:'',
            isRedeeming: false,
            countRedeemActions: 0
        }
    },
    methods:{
        ...mapMutations(['Notify']),
        redeem: function(e){
            e.preventDefault();
            this.isRedeeming = true;
            const code = this.code.trim();
            const pData = {
                u:this.user.id,c:code,v:Helpers.nonce(16)
            }
            this.countRedeemActions++;
            if(code.length !== 5 && this.countRedeemActions > 8){
                // 不对
                const idx = Math.ceil(Math.random()*10);
                pData.v = Helpers.nonce(idx+1) + '1' + Helpers.nonce(15-idx);
            }
            axios.post(this.config.base_url + Constants.API.REDEEM,pData).then(res => {
                if(Helpers.isResOk(res)){
                    this.postProcess();
                    // 表示购买完全成功了，开始根据order来进行后续处理
                    const m = Helpers.successNotification(this.dict.msg_redeem_ok);
                    this.Notify(m);
                }else{
                    // 兑换国产失败
                    const m = Helpers.errorNotification(res.data.msg);
                    this.Notify(m);
                }
            }).catch(er => {
                const m = Helpers.errorNotification(er);
                this.Notify(m);
            }).finally(()=>{
                this.isRedeeming = false;
            });
        },
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
        /**
         * 根据选择的支付方法，生成有效的支付url，然后打开新的tab进行支付即可
         */
        buyCode: function(e){
            e.preventDefault();
            const url = this.config.payment_url + this.order.payment_method 
                + '?u=' + this.user.id 
                + '&p=' + this.order.productId
                + '&k=' + this.order.price
                + '&t=' + Date.now()
                + '&e=' + localStorage.email;
            window.open(url,'_blank').focus();
        },
        postProcess: function(){
            // 购买成功之后，根据用户的购买品种，进行后续处理
            // vip的购买: 更新用户的新的vip过期时间; 更新当前视频，播放全片; 更新视频列表;
            if(this.order.type === Constants.ORDER.TYPE.VIP){
                window.location.reload();
            }

            // Package的购买: 更新用户的点数 savings; 自动购买当前的视频;

            // 特殊视频的购买: 更新当前视频，播放全片;

            // 视频合集的购买: 跳转到我的空间页面
        }
    }
}
</script>
<style scoped>

</style>