<template>
    <div :class="collapsed?'collapsed':''" class="border-2 rounded-md border-gray-900 bg-highlight sci-fi-blow overflow-hidden categories p-3">
        <div class="categories-wrap float-left">
            <Category :c="c" v-for="(c,idx) in categories" :key="idx" @on-clicked="onClicked"></Category>
        </div>
        <div class="show-btn-wrap float-right text-yellow-500" @click="toggle" v-if="categories.length > 0">
            <svg v-if="collapsed" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
            </svg>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Category from './Category.vue';
export default {
    name: "Categories",
    components:{
        Category
    },
    computed:{
        ...mapGetters(['categories']),
    },
    data(){
        return {
            collapsed: true,
        }
    },
    methods:{
        toggle: function(){
            this.collapsed = !this.collapsed;
        },
        onClicked: function(payload){
            this.collapsed = true;
            this.$emit('on-category-clicked',payload);
        }
    }
}
</script>
<style scoped>
.categories{
    margin: 7px 7px 0 7px;
}
.collapsed{
    height: 70px;
}
.categories-wrap{
    width: 94%;
}
.show-btn-wrap{
    width: 6%;
}
.show-btn-wrap svg{
    margin-left: -5px;
}
</style>