<template>
<div class="px-2 py-3 flex items-center justify-between sm:px-6" v-if="pages_count>0">
  <div class="flex-1 flex justify-between sm:hidden">
    <a href="#" @click="loadPage(-1, $event)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
      &lt;
    </a>
    <div>
      <div class="relative inline-block text-left" @click="toggleJumper" v-if="pageByPage">
            <div>
                <span class="text-white">第{{ current_pn_obj.num }}页</span>
            </div>
        </div>
        <div class="relative inline-block text-left" @click="toggleJumper" v-else>
            <div>
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                第{{ current_pn_obj.num }}页
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </button>
            </div>
        </div>
    </div>
    <a href="#" @click="loadPage(1, $event)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
      &gt;
    </a>
  </div>

  <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p class="text-sm text-white">
        Showing
        <span class="font-medium">{{ startIdx }}</span>
        to
        <span class="font-medium">{{ endIdx }}</span>
        of
        <span class="font-medium">{{ totalVideos }}</span>
        videos
      </p>
    </div>

    <div>
      <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <a href="#" @click="loadPage(-1, $event)" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          &lt;
        </a>
        <a v-if="pages_count>10" href="#" @click="loadPage(-10, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          -10
        </a>
        <a v-if="pages_count>50" href="#" @click="loadPage(-50, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          -50
        </a>
        <a v-if="pages_count>100" href="#" @click="loadPage(-100, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          -100
        </a>

        <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-red-700">
          第{{ current_pn_obj.num }}页
        </span>
        
        <a v-if="pages_count>100" href="#"  @click="loadPage(100, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          +100
        </a>
        <a v-if="pages_count>50" href="#" @click="loadPage(50, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          +50
        </a>
        <a v-if="pages_count>10" href="#" @click="loadPage(10, $event)" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          +10
        </a>
        <a href="#" @click="loadPage(1, $event)" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          &gt;
        </a>
      </nav>
    </div>
  </div>

    <div v-show="jumperVisible" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <div class="relative w-96">
            <div class="absolute top-0 left-0 -ml-8 pt-10 pr-2 flex sm:-ml-10 sm:pr-4">
              <button class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Close panel</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="h-full bg-white p-4 overflow-y-auto">
              <div class="pb-16 space-y-6 pt-12">
                <div>
                  <div class="mt-4 flex items-start justify-between">
                    <div>
                      <h2 class="text-lg font-medium text-gray-900">第{{ current_pn_obj.num }}页</h2>
                    </div>
                    <button  @click="toggleJumper" type="button" class="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-red-500">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <span @click="goToPageDirect(idx, $event)" v-for="idx in pIdxArray" :key="idx+'pi'" class="inline-flex items-center px-2 m-1 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                    {{ idx === pages_count ? idx+' 最后一' : idx }}页
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

</template>

<script>
import Helpers from '../../common/Helpers';
import {mapGetters, mapMutations} from 'vuex';
import Constants from '../../common/Constants';
export default {
    name: 'Pagination',
    props:{
      search:{
        type: Boolean,
        required: false,
        default: function(){
          return false;
        }
      },
      type: {
        type: Number,
        required: true,
      }
    },
    computed:{
        ...mapGetters(['pages_count','current_pn_obj','current_page_video_type','last_search_query','dict']),
        totalVideos: function(){
            return this.pages_count * this.pageSize;
        },
        startIdx: function(){
            return (this.current_pn_obj.num - 1) * this.pageSize + 1
        },
        endIdx: function(){
            return this.startIdx + this.pageSize - 1;
        },
        pageByPage: function(){
          return this.search || this.current_page_video_type === Constants.LIST_TYPE.MY_VIDEOS || this.current_page_video_type === Constants.LIST_TYPE.RICH;
        },
        pIdxArray: function(){
          const arr = [];
          let theStep = 10;
          if(this.pages_count <= 20){
            theStep = 1;
          }
          for(let i = 1; i < this.pages_count; i+=theStep){
            arr.push(i);
          }
          if(arr[arr.length-1] < this.pages_count){
            arr.push(this.pages_count);
          }
          return arr;
        }
    },
    data(){
        return {
            pageSize: 18, // 默认的page size
            jumperVisible: false, // 默认不显示多页码的选择器
        }
    },
    mounted(){
      this.UpdateCurrentPageVideosType(this.type);
    },
    methods:{
        ...mapMutations(['UpdateCurentPageNumber','UpdateCurrentPageVideosType','Notify']),
        loadPage: function(step, e){
            e.preventDefault();
            if(this.search && this.last_search_query.trim() === ''){
              const msg = Helpers.noteNotification(this.dict.txt_input_keyword_first);
              this.Notify(msg);
              return;
            }
            // Todo: 验证页码的正确性
            let targetPn = this.current_pn_obj.num + step;
            if( targetPn <= 1){
                targetPn = 1;
            }else if(targetPn > this.pages_count){
              targetPn = this.pages_count
            }
            if(targetPn !== this.current_pn_obj.num)
            {
                this.UpdateCurentPageNumber({
                  num: targetPn,
                  param: '',
                });
                this.$emit('pn-updated',{pn: targetPn, param: this.current_pn_obj.param});
            }
            this.jumperVisible = false;
        },
        toggleJumper: function(){
            this.jumperVisible = !this.jumperVisible;
        },
        goToPageDirect: function(pageNumber, e){
            e.preventDefault();
            this.UpdateCurentPageNumber({
              num: pageNumber,
              param: '',
            });
            this.$emit('pn-updated',{pn: pageNumber, param: this.current_pn_obj.param});
            this.jumperVisible = false;
        }
    }
}
</script>
<style scoped>

</style>>