<template>
    <div class="relative py-8 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
            </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
            <div class="text-lg max-w-prose mx-auto">
            <h1>
                <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    Welcome to Zimu7
                </span>
            </h1>
            <p class="mt-8 text-xl text-gray-500 leading-8">
                Zimu7 contains all kinds BDSM/erotic films. Inside you'll find real Chinese mistresses private and live stream videos.
            </p>
            <p class="mt-4 text-xl text-red-600 leading-8">
                NOTE: This is an adult site. If you are under 18, you must not proceed beyond this page.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
                字母圈聚合了众多华人最美女主的订制与直播调教视频.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
                注意: 本站只适合18岁以上人群使用. 如果你不满18岁, 请马上离开.
            </p>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button @click="yes($event)" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          Yes,I'm over 18,let me in. 我年满18岁,进入站点
        </button>
        <button @click="no($event)" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          No I'm under 18, get me out of here. 我未满18岁,让我离开
        </button>
      </div>

        </div>
    </div>
</template>

<script>
export default {
  name: "Age",
  methods:{
      yes: function(e){
        e.preventDefault();
        localStorage.age = 'y';
        this.$router.push({
            path:'/'
        });
      },
      no: function(e){
        e.preventDefault();
        localStorage.age = 'n';
        window.location.href = 'https://google.com';
      },
  }
};
</script>
