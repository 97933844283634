const Constants = {
    API:{
        RES:{
            OK:200,
            GOOD: 0,
        },
        GET_CONFIG: 'https://devapp.zmq7.com/api/get_config', // 获取配置数据的 api 以后也要改成静态的内容即可
        USER:{
            LOGIN:'api/user/login',
            SIGN_UP:'api/user/register',
            FETCH: 'api/user/get_profile',
            UPDATE_PWD: 'api/user/update_password',
            JOIN_ENQ: 'api/user/enquire',
            SWITCH_DEVICE: 'api/user/switch_device',
        },
        VIDEO:{
            FETCH_VIDEOS: 'api/resource/home',
            GET: 'api/resource/get',
            RELATED_VIDEOS: 'api/resource/related',
            BUY_BY_POINT: 'api/resource/buy_by_point',
            SEARCH: 'api/resource/search',
            DOWMLOAD: 'api/resource/download',
            FETCH_DISS: 'api/resource/dms',
            DIS: 'api/resource/dm',
            // 收藏视频
            FAV: 'api/resource/fav',
            THUMB_UP: 'api/resource/thumb_up',
            // VIP兑换custom的路由
            DEDUCT_VIP_FOR_CUSTOM:'api/resource/deduct_vip_for_custom',
        },
        REDEEM: 'api/resource/redeem',
        ORDER:{
            CREATE: 'api/order/create_rta',
            ACTIVATE: 'api/order/activate_rta',
            VERIFY_KAMI:'api/kami/verify'
        },
        QUEEN:{
            FETCH_QUEENS:'api/resource/mis',
            LATEST_VIDEOS: 'api/resource/mis_latest',
        },
        COLLECTION: {
            FETCH:'api/resource/collection',
            FETCH_VIDEOS:'api/resource/collection_videos',
            REDEEM:'api/resource/collection_redeem'
        }
    },
    // Notification related
    MSG_TYPE:{
        NOTE:'Note',
        SUCCESS:'Success',
        ERROR:'Error',
        ALERT:'Alert',
    },
    NOTIFICATION_AUTO_DISMISSED_IN: 3000,
    EX_RATE:5,
    ORDER:{
        TYPE:{
            VIP:1,
            PACKAGE:2,
            FANS:3, // S的私有会员
            COLLECTION:4,
            CUSTOM_VIDEO:5,
            THIRD_PARTY_CARD: 6, // 购买第三方的点卡进行兑换用
        },
        PAYMENT_METHOD:{
            WECHT:'w',
            ALIPAY:'a',
            CREDIT_CARD:'c',
            CRYPTO:'b',
            OFFLINE:'o', // 线下购买
            THIRD_PARTY_CARD: 't', // 购买第三方的点卡进行兑换用
        },
    },
    // 和使用的语言相关
    LANG:{
        EN:'en',
        CN:'cn'
    },
    LIST_TYPE:{
        SIMPLE:0, // 首页普通集合
        RICH:1, // 重集合
        SEARCH:2, // 搜索视频集合
        QUEEN:3, // QUEEN 集合
        MY_VIDEOS:10, // 我的视频集合
        MY_FAVORITE:11, // 我的收藏
    },
    DICT:{
        en:{
            sign_up: 'Sign Up',
            sign_in: 'Sign In',
            sign_out: 'Logout',
            switch_lang: '中文',
            home:'Home',
            custom:'Custom',
            my_space:'My Space',
            wait:'Please wait ...',
            next_page:'Next page',
            prev_page:'Prev page',
            // 登陆页面
            login_form_title: 'Sign in to your account',
            forget_password: 'Forgot your password?',
            remember_me:'Remember me',
            Password:'Password',
            Email_address:'Email address',
            sign_in_success:'Welcome back ',
            // 注册页面
            sign_up_form_title: 'Create Your Account',
            sign_up_name: 'Nickname',
            confirm_password: 'Confirm Password',
            sign_up_success:'All good! Welcome ',
            sign_up_email_ph: 'Your email',
            sign_up_pwd_ph: 'At least 6 letters',
            sign_up_pwd_re_ph: 'Confirm Password',
            // 视频相关
            duration: 'Duration',
            // 结账页面
            choose_payment: "Choose Payment",
            choose_payment_sub: "This is not a subscription, but a monthly membership only on your demand!",
            choose_payment_sub2: "Note: You must be elder than 18 year old; If Can not pay, please Contact Us; To go next steps, which means you have read and agreed with our Terms.",
            choose_payment_sub3: "Currency: AUD, Australian Dollar.",
            wechat_pay_txt: "WeChat Pay",
            alipay_pay_txt: "Alipay",
            crypto_pay_txt: "Crypto Currency",
            credit_card_txt: "Credit Card",
            apple_card_txt: "Apple e-Gift Card",
            offline_pay_txt: "Daigo",
            // 兑换
            redeem_widget_title:'Thank You!',
            redeem_subtitle:'We can not accept the payment directly, please click the "Get Redemption Code" button first 🙏. If any question, please email us to ',
            redeem_subtitle2:'Please enter the code which you got to Redeem.',
            already_got_code:'Already Got Code',
            note_code_failed: '',
            fill_in_code:'Enter Redemption Code',
            btn_get_code:'Get Redemption Code',
            btn_redeem:'Redeem',
            btn_redeem_return:'Go Back',
            msg_redeem_ok: 'All Done, Thank you very much!',
            msg_redeem_ok2: 'Redeemed, please go to "My Space" to watch',
            loading_video: 'Loading ...',
            // 代购
            daigo_widget_title: 'Find a Buyer',
            daigo_widget_intro1:'Please use Line to contact with us by scanning the following QR-Code',
            daigo_widget_intro2:'',
            daigo_widget_intro3:'',
            daigo_widget_intro4:'',
            daigo_widget_intro5:'',
            daigo_widget_intro7:'',
            daigo_widget_intro6:'If you\'re living in Australia, we can accept the bank transfer. For other countries, please use Line or send your enquiry to ',
            daigo_widget_agree_text: 'I have read and agree all above',
            daigo_widget_btn_txt:'Find a Buyer',
            GoBack:'Back',
            // my space
            txt_update_pwd: 'Update Password',
            txt_pwd_no_same: '2 passwords are not same, please check.',
            txt_cancel: 'Cancel',
            txt_new:"New",
            txt_update_pwd_done:'Your password has been updated',
            // search
            txt_Result_Found: 'Videos found',
            txt_Search: 'Search',
            txt_Searching: 'Searching ...',
            txt_Items: '',
            // 其他
            txt_redeem_this:'Redeem it',
            txt_claim: 'For the purposes of 18 U.S.C. § 2257, we are not a producer (primary or secondary) of any content found on this website. For § 2257 records for any content found on this website, please direct your request to the producer, site or user which produced or uploaded the content.',
            txt_download: 'Download',
            txt_contact_us: 'Contact Us',
            txt_like:'Like',
            txt_save:'Save',
            txt_report:'Report',
            // Mistress
            txt_mistress: 'Asian Mistress',
            txt_confirm_to_pay: 'Confirm to Pay',
            direct_pay_error: 'Please try again',
            direct_pay_notes: 'Please click the button below to activate your VIP membership',
            txt_cn_user_only:'Only users from China Mainland',
            txt_profile:'Profile',
            txt_q_pro:'Pro',
            txt_q_feet:'Feet/Leg',
            txt_q_looking:'Looking',
            txt_q_shape:'Body',
            txt_q_playback:'Latest Playbacks',
            txt_q_appraisal:'Rating',
            txt_q_all_v:'\'s all works',
            // 精选集合
            txt_collection:'Albums',
            txt_price:'Price',
            txt_buy:'Buy',
            txt_charge:'Charge',
            txt_brand:'2BZHA',
            txt_custom_video:'Buy Premium Video',
            txt_use_vip_for_custom_video:'Deduct VIP time to watch',
            txt_to_deduct:'Deduct',
            txt_days:'days, click to watch now',
            txt_points:' Points',
            txt_input_keyword_first:'Please input the keyword ..',
            // 弹幕
            txt_dm_dan:'Barrage',
            txt_dm_write:'Write',
            txt_dm_form_title: 'Say something to the host ..',
            txt_Send: 'Send',
            // 申请表
            txt_cooperate:'Be our Partner',
            txt_ad_agent: 'Ads Agent',
            txt_seller: 'Seller',
            txt_seller_type1:'Take payment and deliver product by myself',
            txt_seller_type2:'Content provider only',
            txt_daigo: 'Daigo(Buyer)',
            txt_my_contact: 'My Contact Detail',
            txt_my_contact_placeholder: 'Email,QQ,WeChat,Line,Telegram or WhatsApp',
            txt_my_contact_notes: 'We will keep your details secret.',
            txt_btn_submit: 'Submit',
            txt_optional: 'Optional',
            txt_required: 'Required',
            txt_note: 'Notes',
            txt_enquire_ok: 'All good, we will be in touch soon, thanks.',
            txt_visitor: 'Visitor',
            txt_switch_device: 'Disable this device',
            txt_switch_device_waring: 'Caution, you can not watch our movies on this devices if you click the "YES" button, until you switch back from the other device. Please be making sure of this action.',
            txt_keep_device: 'No, I DO NOT want',
            txt_confirm_switch: 'Yes, I Got New Device',
            // 使用电子礼品卡
            txt_gift_title: "Credit Card or PayPal",
            txt_gift_subtitle: "If you could buy an eGift card on Amazon Australia as a donation, we will activate the VIP membership/Videos as returned. Please follow the steps below:",
            txt_gift_step1: '1:Register yourself to create a new account in Amazon Australia <a class="text-blue-500" href="https://www.amazon.com.au/" target="_blank">https://www.amazon.com.au</a> if you have not signed up yet.',
            txt_gift_step2: '2:Buy any eGift card amount more than AUD $20. Please ensure the currency must be in <span class="text-red-600">Australian dollars, any other currency will not be acceptable by us</span>.',
            txt_gift_step3: '3:Please note: Use your own email as the receiver, but not ours. Because you can easily get refund from Amazon if anything goes wrong.',
            txt_gift_step4: '4:Please <a class="text-blue-500" href="mailto:zimu7seven@gmail.com">email us</a> the eGift card\'s claim code which Amazon sends to you, then we will activate your VIP membership accordingly in a short time.',
            txt_gift_note: "Sorry for bothering you because we can not take Credit Card or PayPal payment",
            txt_must_buy: "Premium Video",
            // 使用苹果礼品卡
            txt_apple_title: "Credit Card or PayPal",
            txt_apple_subtitle: "If you could buy a Gift card on Apple Australia as a donation, we will activate the VIP membership/Videos as returned. Please follow the steps below:",
            txt_apple_step1: '1: Click to visit <a class="text-blue-500" href="https://www.apple.com/au/shop/buy-giftcard/giftcard" target="_blank">Apple Australia Official Gift</a>.',
            txt_apple_step2: '2: Buy any Gift card amount more than AUD $20. Please ensure the currency must be in <span class="text-red-600">Australian dollars, any other currency will not be acceptable by us</span>.',
            txt_apple_step3: '3: Please note: Use your own email as the recipient, but not ours. Because you can easily get refund from Apple if anything goes wrong.',
            txt_apple_step4: '4:Please <a class="text-blue-500" href="mailto:zimu7seven@gmail.com">email us</a> the Gift card\'s redeem code which Apple sends to you, then we will activate your VIP membership accordingly/Or any videos in a short time.',
            txt_apple_note: "Please consider the Amazon e-gift card as your deposit on our website, and you can use it to exchange any resources with us",
        },
        cn:{
            sign_up: '注册',
            sign_in: '登陆',
            sign_out: '退出',
            switch_lang: 'English',
            home:'首页',
            custom:'重口',
            my_space:'我的空间',
            wait:'请等待 ...',
            next_page:'下一页',
            prev_page:'上一页',
            // 登陆页面
            login_form_title: '用户登陆',
            forget_password: '忘记密码?',
            remember_me:'记住我',
            Password:'密码',
            Email_address:'邮件地址',
            sign_in_success:'登陆成功, 欢迎',
            // 注册页面
            sign_up_form_title: '新用户注册',
            sign_up_name: '昵称',
            confirm_password: '确认密码',
            sign_up_success:'注册成功! 欢迎',
            sign_up_email_ph: '请输入有效邮件地址',
            sign_up_pwd_ph: '最少6位长度',
            sign_up_pwd_re_ph: '再输入一次密码',
            // 视频相关
            duration: '时长',
            // 结账页面
            choose_payment: "请选择支付方式",
            choose_payment_sub: "无论哪种付款方式,会员过期之后都不会自动续费",
            choose_payment_sub2: "用户须年满18周岁成年人, 否则不提供服务;如支付成功后没有开通,请直接邮件联系我们;付款后,表示您已同意本站的使用条款,且恕不退款!",
            choose_payment_sub3: "货币单位为澳大利亚元，兑换人民币的汇率约在1比5左右,实际汇率以支付平台的实时汇率为准。",
            wechat_pay_txt: "微信支付",
            alipay_pay_txt: "支付宝",
            crypto_pay_txt: "虚拟币",
            credit_card_txt: "信用卡",
            apple_card_txt: "用苹果礼品卡兑换资源",
            offline_pay_txt: "找代购",
            // 兑换
            redeem_widget_title:'感谢您的选择字母圈',
            redeem_subtitle:'由于特殊的原因, 我们无法直接接受直接付款,请点击"获取兑换码"按钮,进行购买。麻烦您了🙏. 如出现任何问题，请联系',
            redeem_subtitle2:'然后将得到的兑换码填入下面的表格，点击"兑换"按钮即可。',
            already_got_code:'已有兑换码',
            note_code_failed:'提示: 如果您支付成功却没有获得兑换码, 请立刻发 “带订单号的支付成功详情截图” 到上面的联系邮件即可，我们会在几个小时内处理。如果您等不及，您可以再次支付购买一次，我们会根据您的要求进行退款或作为您的预存费用。您无需担心，字母圈永远诚信为本！感谢您的支持！',
            fill_in_code:'请填入兑换码',
            btn_get_code:'获取兑换码',
            btn_redeem:'兑换',
            btn_redeem_return:'选择其他付款方式',
            msg_redeem_ok: '兑换成功, 感谢您的支持!',
            msg_redeem_ok2: '兑换成功, 感谢您的支持! 请到我的空间页面观看',
            loading_video: '视频资源拼命加载中 ...',
            // 代购
            daigo_widget_title: '通过代购来购买',
            daigo_widget_intro1:'由于特殊的原因, 我们无法直接接受付款,请点击"发送邮件"按钮, 写上您的微信, 会有代购和您联系. 但请您务必注意:',
            daigo_widget_intro2:'第2: 如果您曾经购买过VIP会员, 则代购必须先协助开通VIP后，您再付款.',
            daigo_widget_intro3:'第3：VIP开通后, 代购没有关闭的权限, 您无需担心被骗.',
            daigo_widget_intro4:'第4：合作的代购, 都是人品很好的圈内同好, 您无需担心隐私问题. 但是还是请您保护您的个人信息, 不要轻易尝试交朋友.',
            daigo_widget_intro5:'第5：如果您通过代购，购买任何本站以外的任何东西, 本站概不负责, 请您自行斟酌, 保护个人信息和财产安全！切记切记。',
            daigo_widget_intro7:'第6：如果您通过代购，购买点数, 独家视频/精选视频合集，或者您第一次开通VIP，请先行付款.',
            daigo_widget_intro6:'第1：代购不是本站员工, 代购表达的任何观点或建议, 均为其个人观点, 不代表本站. 如果您有疑问, 或想联系一个代购, 请直接通过邮件联系本站',
            daigo_widget_agree_text: '我已阅读并完全理解以上信息',
            daigo_widget_btn_txt:'联系一个代购',
            GoBack:'返回',
            // my space
            txt_update_pwd: '修改密码',
            txt_pwd_no_same: '您输入的新密码不一致!',
            txt_cancel: '取消修改',
            txt_new:"新",
            txt_update_pwd_done:'密码已经成功的修改了',
            // search
            txt_Result_Found: '搜索到的视频',
            txt_Search: '搜索',
            txt_Searching: '搜索中 ...',
            txt_Items: '个',
            // 其他
            txt_redeem_this:'兑换本视频',
            txt_claim: '本视频由网友上传共享, 请联系我们说明本网站提供内容系本人或法人版权所有. 本网站在核实后，有权先行撤除，以保护版权拥有者的权益',
            txt_download: '下载',
            txt_contact_us: '联系我们',
            txt_like:'牛逼',
            txt_save:'收藏',
            txt_report:'报错',
            // Mistress
            txt_mistress: '女主天地',
            txt_confirm_to_pay: '使用支付宝付款',
            direct_pay_error: '发起支付失败, 请再试一试',
            direct_pay_notes: '如果您已经支付成功, 一般情况下, 您需要刷新几次页面, 才能在页面顶部看到VIP被激活的标记. 如果还是看不到, 请点击下面的按钮来激活您的订单（包括VIP会员/代售视频/精选集等). 如有问题，请邮件联系 ',
            txt_activate_btn: '支付成功, 激活订单',
            txt_activate_success: '您的订单已经激活成功了,请到我的空间查看即可',
            txt_cn_user_only:'仅中国大陆用户有效',
            txt_profile:'女主个人资料',
            txt_q_pro:'调教手法',
            txt_q_feet:'美腿玉足',
            txt_q_looking:'颜值',
            txt_q_shape:'身材',
            txt_q_playback:'最新回放',
            txt_q_appraisal:'评价',
            txt_q_all_v:'的全部视频',
            // 精选集合
            txt_collection:'精选集',
            txt_price:'价格',
            txt_buy:'购买',
            txt_charge:'充值',
            txt_brand:'字母圈',
            txt_custom_video:'点击单独购买',
            txt_use_vip_for_custom_video:'用我的VIP时间兑换本片',
            txt_to_deduct:'消耗',
            txt_days:'天的VIP, 点击立刻观看',
            txt_points:'点',
            txt_input_keyword_first:'请先输入搜索关键字',
            // 弹幕
            txt_dm_dan:'弹',
            txt_dm_write:'点我发弹幕',
            txt_dm_form_title: '来评!主播正在来翻牌子的路上..',
            txt_Send: '发送',
            // 申请表
            txt_cooperate:'合作加盟',
            txt_ad_agent: '广告代理',
            txt_seller: '代售卖家',
            txt_seller_type1:'自己收款/自己交付产品',
            txt_seller_type2:'由字母圈收款并交付产品',
            txt_daigo: '代购',
            txt_my_contact: '我的联系信息',
            txt_my_contact_placeholder: '电子邮件/QQ/微信/Line/Telegram等均可',
            txt_my_contact_notes: '您提交的联系信息不会公开给任何第三方',
            txt_btn_submit: '提交',
            txt_optional: '选填',
            txt_required: '必填',
            txt_note: '其他要求',
            txt_enquire_ok: '您的申请已经收到,谢谢!我们会尽快和您取得联系.',
            txt_visitor: '游客',
            txt_switch_device: '不再使用本设备看片',
            txt_switch_device_waring: '请您确定, 以后不再使用本设备观看本站影片, 一旦您点击下面的"确认"按钮, 您将只能在其他的设备上观影, 直到您再切换回来.',
            txt_keep_device: '继续使用这个设备',
            txt_confirm_switch: '确认,切换到新设备',
            // 使用电子礼品卡
            txt_gift_title: "使用信用卡或PayPal",
            txt_gift_subtitle: "您可以通过捐赠澳洲亚马逊（Amazon AU）电子礼品卡的方式, 兑换VIP会员或本站任意资源. 方法很简单:",
            txt_gift_step1: '1:务必注册一个澳洲亚马逊的会员<a class="text-blue-500" href="https://www.amazon.com.au/" target="_blank">https://www.amazon.com.au</a>',
            txt_gift_step2: '2:在澳洲亚马逊官网购买任何价值大于AUD $20澳元的电子礼品卡(eGift card). <span class="text-red-600">注意，必须是澳元的, 其他币种的都不可以.</span>',
            txt_gift_step3: '3:礼品卡的接收人，记得填写您自己的邮箱，不要填写我们的邮件作为接收方. 这样如果出现问题，您可以方便的找Amazon退款.',
            txt_gift_step4: '4:购买成功之后，您会收到亚马逊发给您的电子邮件或短信. 请把电子礼品卡的兑换码(claim code)发送到<a class="text-blue-500" href="mailto:zimu7seven@gmail.com">zimu7seven@gmail.com</a>, 我们会根据您的礼品卡金额, 为您开通VIP时长或任何资源.',
            txt_gift_note: "您也可以通过此种方式购买本站的任意资源.",
            txt_must_buy: "代售视频,VIP无效",
            // 使用苹果礼品卡
            txt_apple_title: "使用澳大利亚苹果礼品卡进行兑换",
            txt_apple_subtitle: "您可以通过捐赠澳大利亚苹果礼品卡（Apple AU）电子礼品卡的方式, 兑换VIP会员或本站任意资源. 方法很简单:",
            txt_apple_step1: '1: 访问澳洲苹果的官网礼品卡页面 <a class="text-blue-500" href="https://www.apple.com/au/shop/buy-giftcard/giftcard" target="_blank">https://www.apple.com/au/shop/buy-giftcard/giftcard</a>.',
            txt_apple_step2: '2: 在澳洲苹果官网购买任何价值大于AUD $20澳元的电子礼品卡(Gift card) <span class="text-red-600">注意，必须是澳元的, 其他币种的都不可以.</span>.',
            txt_apple_step3: '3: 礼品卡的接收人，记得填写您自己的邮箱，不要填写我们的邮件作为接收者. 这样如果出现问题，您可以方便的找苹果退款.',
            txt_apple_step4: '4: 购买成功之后，您会收到苹果发给您的电子邮件或短信. 请把电子礼品卡的兑换码(redeem code)发送到<a class="text-blue-500" href="mailto:zimu7seven@gmail.com">zimu7seven@gmail.com</a>, 我们会根据您的礼品卡金额, 为您开通VIP时长或站内任意视频资源.',
            txt_apple_note: "您的金额, 相当于您在本站的预存, 可以通过此种方式购买本站的任意资源.",
        },
    }
}

export default Constants;