<template>
<div class="fixed inset-0 overflow-hidden z-40" aria-labelledby="slide-over-title" role="dialog" aria-modal="true" v-if="visible">
  <div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0" aria-hidden="true"></div>
    <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
      <div class="w-screen max-w-md">
        <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
          <div class="flex-1 h-0 overflow-y-auto">
            <div class="py-6 px-4 bg-indigo-700 sm:px-6">
              <div class="flex items-center justify-between">
                <h2 class="text-lg font-medium text-white" id="slide-over-title">
                  {{ dict.choose_payment }}
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button @click="cancelOrder($event)" type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-between">
                <button v-if="pmSupport('w') && cnUser" @click="confirm('w',$event)" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-transparent shadow-sm text-sm rounded-lg text-white bg-wechat-green hover:bg-green-500 focus:outline-none">
                    {{ dict.txt_cn_user_only }}:{{ dict.wechat_pay_txt }}
                </button>
                <button v-if="pmSupport('a') && cnUser" @click="confirm('a',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-gray-300 shadow-sm text-sm rounded-lg text-gray-800 bg-white hover:bg-gray-100 focus:outline-none">
                    {{ dict.txt_cn_user_only }}:{{ dict.alipay_pay_txt }}
                </button>
                <button v-if="pmSupport('t') && cnUser && user.ioc" @click="confirm('t',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-gray-300 shadow-sm text-sm rounded-lg text-gray-800 bg-white hover:bg-gray-100 focus:outline-none">
                    {{ dict.alipay_pay_txt }}/{{ dict.wechat_pay_txt }} 卡密充值
                </button>
                <p v-if="payByMailOnly && cnUser" class="text-sm text-red-500 m-2">
                  请通过邮件联系客服 <a :href="config.qq+'@gmail.com'" class="text-blue-600">{{ config.qq }}@gmail.com</a> 获取充值方式. 邮件里请一定要写明您在本站的账户邮箱, 准备购买什么, 否则一律不回复. 谢谢
                </p>
                <button @click="confirm('b',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-transparent shadow-sm text-sm rounded-lg text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none">
                    {{ dict.crypto_pay_txt }}
                </button>
                <button v-if="pmSupport('c')" @click="confirm('c',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-gray-300 shadow-sm text-sm rounded-lg text-gray-800 bg-white hover:bg-gray-100 focus:outline-none">
                    {{ dict.credit_card_txt }} {{ priceText }}
                </button>
                <button v-if="pmSupport('c')" @click="confirm('apple',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-gray-300 shadow-sm text-sm rounded-lg text-white bg-black hover:bg-gray-100 focus:outline-none">
                    {{ dict.apple_card_txt }}
                </button>
                <button v-if="pmSupport('o') && !cnUser" @click="confirm('o',$event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                    {{ dict.offline_pay_txt }} {{ priceText }}
                </button>
                <button v-if="pmSupport('a') && !cnUser" @click="openSubMenu($event)" type="button" class="inline-flex items-center mx-6 my-3 px-6 py-3 border border-gray-300 shadow-sm text-sm rounded-lg text-gray-800 bg-gray-200 hover:bg-gray-100 focus:outline-none">
                     AliPay(支付宝)
                    <br>
                    Available in Chinese Version Only, Please click me to switch language into CN. 仅在中文版本有效，请点击进行切换.
                </button>
            </div>
          </div>
          <div class="flex-shrink-0 px-4 py-4 flex justify-end">
            <div class="mt-1">
                <p class="text-sm text-black mb-2">
                    {{ dict.choose_payment_sub }}
                </p>
                <p class="text-sm text-red-500">
                    {{ dict.choose_payment_sub2 }}
                </p>
              </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import Helpers from '../../../common/Helpers';
export default {
    name:"Checkout",
    props:{
        visible:{
            type: Boolean,
            required: true,
        }
    },
    computed:{
        /**
         * order 表示用户订购的东西:
         * @property type: VIP/点数/定制视频产品/精选集合
         * @property productId: 所关联的产品的id
         * @property price: 整数
         * @property payment_method: 支付方式, 微信/支付宝/虚拟币/信用卡/单独支付
         */
        ...mapGetters(['order','dict','config','current_video','current_lang','user']),
        priceText: function(){
            return Helpers.priceText(this.order.price, this.current_video.s ===1);
        },
        cnUser: function(){
          return this.current_lang === 'cn';
        },
        payByMailOnly: function(){
          return this.user.ap.trim().length === 0;
        },
    },
    methods:{
        ...mapMutations(['UpdateOrder','UpdateSubmenuVisible']),
        /**
         * 用户关闭了选择支付方式的窗口，表示放弃此次订单
         */
        cancelOrder: function(e){
            e.preventDefault();
            this.UpdateOrder({});
            this.$emit('cancelled');
        },
        /**
         * 判断给定的支付方式是否支持
         * @param s: String
         */
        pmSupport: function(s){
            return this.config.pm.indexOf(s) > -1 && this.user.ap.indexOf(s) > -1;
        },
        /**
         * 用户确认了某个支付方式，将关闭窗口，发布事件. 应该进入真正的支付流程
         * @param pm: String
         */
        confirm: function(pm, e){
            e.preventDefault();
            const o = {
                payment_method: pm,
                price: this.order.price,
                productId: this.order.productId,
                type: this.order.type,
            }
            this.UpdateOrder(o);
            this.$emit('payment-method-selected');
        },
        openSubMenu: function(e){
          e.preventDefault();
          this.cancelOrder(e);
          this.UpdateSubmenuVisible(true);
        }
    }
}
</script>
<style scoped>
.bg-wechat-green{
    background-color: #68c864;
}
</style>