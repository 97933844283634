<template>
<div class="bg-black" :class="isSimple ? 'pb-20': 'pb-4'">
    <Categories @on-category-clicked="onCategoryClicked"></Categories>
    <ul v-if="isSimple" class="grid grid-cols-2 gap-3 sm:grid-cols-2 lg:grid-cols-6 p-2 bg-black">
        <video-card
            v-for="(video, idx) in search? videos_search : videos"
            :key="idx"
            :video="video"
            :dict="dict"
            :sb="config.static_base"
            @video-selected="onVideoSelected"
        ></video-card>
    </ul>
    <ul v-if="!isSimple" class="grid grid-cols-2 gap-3 sm:grid-cols-2 lg:grid-cols-6 p-2 bg-black">
        <video-card
            v-for="(video, idx) in (search ? videos_search : videos)"
            :key="idx"
            :video="video"
            :dict="dict"
            :sb="config.static_base"
            @video-selected="onVideoSelected"
        ></video-card>
    </ul>
    <FooterBar :search="search" @pn-updated="reloadVideos" :type="type" v-if="!hasMore"></FooterBar>
    <Pagination class="hidden md:block" :search="search" :type="type" @pn-updated="reloadVideos" v-if="!hasMore"></Pagination>
    <LoadMoreBtn :type="type" v-if="hasMore"></LoadMoreBtn>
</div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Constants from '../common/Constants';
import VideoCard from './Widgets/VideoCard.vue';
import FooterBar from "../components/FooterBar.vue";
import Pagination from "../components/Widgets/Pagination.vue";
import Categories from "../components/Category/Categories.vue";
import LoadMoreBtn from "@/components/Widgets/LoadMoreBtn";
import Helpers from '../common/Helpers';
export default {
    name: "Videos",
    props:{
        search:{
            type: Boolean,
            required: false,
            default: function(){
                return false;
            }
        },
        type: {
            type: Number,
            required: true,
        },
        hasMore:{ // 是否显示 load more 按钮
          type: Boolean,
          required: false,
          default: function(){
            return false;
          }
        }
    },
    components:{
        VideoCard, FooterBar,Pagination,Categories,LoadMoreBtn
    },
    computed:{
        ...mapGetters([
            'videos','user','dict','config','current_page_number','videos_search',
            // 数据集合的种类 普通/重/搜索/queens
            'current_page_video_type','pages_count'
        ]),
        // 可能没用了
        isSimple: function(){
            return this.type === Constants.LIST_TYPE.SIMPLE || this.type === Constants.LIST_TYPE.MY_VIDEOS;
        }
    },
    data(){
        return {
            pageNumber: 1, // 当前的页码
        }
    },
    mounted(){
        if(!this.search){
            this.FetchVideos({type:this.type});
        }
    },
    methods: {
        ...mapActions(['FetchVideos','LoadVideo','SearchAction']),
        ...mapMutations(['UpdateCurrentVideo','UpdateCurentPageNumber','Notify']),
        onVideoSelected: function(payload){
            payload.loggedIn = this.user.id !== null;
            if(payload.loggedIn){
                this.LoadVideo(payload.video.i).then(()=>{
                    this.$emit('video-selected', payload);
                }).catch(err => {
                    const msg = Helpers.errorNotification(err);
                    this.Notify(msg);
                });
            }
            else{
                // 用户没有登陆
                this.$router.push({
                    name:'SignIn'
                });
            }
        },
        reloadVideos: function(payload){
            if(this.search){
                // 这个是关键字搜索
                this.SearchAction(payload.pn);
            }
            else{
                this.FetchVideos({pn: payload.pn, type:this.type, param: payload.param});
            }
        },
        onCategoryClicked: function(payload){
            if(this.search){
                this.UpdateCurentPageNumber({
                  num: 1,
                  param: '',
                });
                this.$emit('on-category-clicked', payload);
            }else{
                this.$router.push({
                    name:'Search',params:{keyword: payload.n}
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
