<template>
<li class="col-span-1 bg-highlight rounded-lg sci-fi-blow">
    <div @click="selectIt($event)" class="overflow-hidden max-h-80">
        <img :src="screenshot"  class="rounded-t-md">
        <img v-for="(ss, idx) in screenshots" :src="ss" :key="idx">
    </div>
    <div class="w-full flex items-center justify-between p-4 space-x-6">
        <div class="">
            <div class="flex items-center space-x-0">
                <span @click="loadByQueen(video.o)" class="flex-shrink-0 inline-block px-0 py-0 rounded-full">
                    <img :src="queenAvatar(video.o.f)"  class="w-9 h-9 rounded-full flex-shrink-0 animate-spin-super-slow" style="margin-top: -10px;">
                </span>
                <span>
                  <p v-if="video.s===1" class="mt--1 text-yellow-500 pl-1 text-xs">
                      {{ dict.txt_must_buy }}
                  </p>
                  <p class="mt-1 text-gray-400 pl-1 text-xs">
                      {{ dict.duration }}: {{ video.d }}
                  </p>
                </span>
            </div>
            <h3 class="text-sm mt-2">
                <span class="text-red-600" @click="loadByQueen(video.o)" v-html="video.o.n"></span>: 
                <span class="text-white" @click="selectIt($event)">{{ video.t }}</span>
            </h3>
        </div>
    </div>
</li>
</template>
<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "VideoCard",
        props:{
            video:{
                type: Object,
                required: true
            },
            sb:{ // 静态内容服务器的地址
                type: String,
                required: false,
            }
        },
        computed:{
            ...mapGetters(['config','dict']),
            screenshot: function(){
              let s = this.video.url.substring(0, this.video.url.length - 41);
                // 检查是否最后一个字符
                const lastC = s.substr(s.length-1,1);
                if(lastC !== '/'){
                  s += '/';
                }
                return this.config.static_base + '/' + s + this.video.i + 'thumb_0002.jpg';
            },
            screenshots: function(){
                if(this.video.ro !== 1){
                    let s = this.video.url.substring(0, this.video.url.length - 41);
                  // 检查是否最后一个字符
                  const lastC = s.substr(s.length-1,1);
                  if(lastC !== '/'){
                    s += '/';
                  }
                    return [
                        this.config.static_base + '/' + s + this.video.i + 'thumb_0003.jpg',
                        this.config.static_base + '/' + s + this.video.i + 'thumb_0001.jpg'
                    ];
                }
                return [];
            }
        },
        methods:{
            selectIt: function(e){
                e.preventDefault();
                this.$emit('video-selected', {video: this.video});
            },
          /**
           * 组合正确的S的头像图片地址
           * @param {string} str
           * @returns {string}
           */
            queenAvatar: function(str){
                if(str !== '/zm_logo_dark.png'){
                    str = this.sb + str;
                }
                return str;
            },
            loadByQueen: function(queen){
                if(queen.i !== null){
                    // 加载特定的queen页面
                    this.$router.push({
                        name:'Search',params:{keyword: queen.n}
                    });
                }else{
                    // 加载搜索页面
                    this.$router.push({
                        name:'Search',params:{keyword: queen.n}
                    });
                }
            }
        }
    }
</script>

<style scoped>
.mt--1{
  margin-top: -8px;
}
</style>
