<template>
    <div>
        <div v-for="(notification, idx) in notifications" :key="idx">
            <notification
                v-if="notification.msg"
                :msg="notification.msg"
                :type="notification.type"
                :title="notification.title"
                :auto-dismiss="notification.autoDismiss"
            ></notification>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Notification from "./Widgets/Notification";
    export default {
        name: "Notifications",
        components:{
            Notification
        },
        computed:{
            ...mapGetters([
                'notifications'
            ])
        }
    }
</script>

<style scoped>

</style>
