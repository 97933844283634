<template>
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-2 sm:px-6">
          <h3 class="mt-1 max-w-2xl text-gray-500">
            <a :href="theLink" target="_blank" class="text-blue-600">点击打开百度网盘</a>
          </h3>
          <h4 class="font-medium leading-6 text-gray-900 mt-2">提取码: {{ theCode }} <span class="text-blue-600" @click="copy">{{ copyTxt }}</span></h4>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BaiduPanBlock',
    props:{
        txt:{
            type: String,
            required: true,
        }
    },
    data(){
        return {
            copyTxt: '复制'
        }
    },
    computed:{
        theLink: function(){
            const arr = this.txt.split(' ');
            const arr1 = arr[0].split('http');
            return 'http' + arr1[1];
        },
        theCode: function(){
           const arr = this.txt.split(' ');
           if (arr.length > 1)
           {
             const arr2 = arr[arr.length-1];
             if (arr2.includes('htfoot.com')){
               return 'htfoot.com';
             }
           }
          return this.txt.substr(-4);
        }
    },
    methods: {
        copy: function(){
            navigator.clipboard.writeText(this.theCode);
            this.copyTxt = '复制成功';
        }
    },
}
</script>