<template>
    <div class="bg-black flex flex-col py-2 sm:px-6 lg:px-8 h-screen player-page">
        <VideoPlayer
            @checkout="onStartCheckout" :pause="video_pause"
        ></VideoPlayer>

        <CheckoutBlock
            :start="startCheckout"
            @checkout-done="checkoutDone"
            @checkout-cancelled="checkoutCancelled"
        ></CheckoutBlock>
    </div>
</template>

<script>
import VideoPlayer from '../components/Widgets/VideoPlayer.vue';
import {mapGetters, mapMutations} from 'vuex';
import CheckoutBlock from '../components/Widgets/CheckoutBlock.vue';

export default {
  name: "VideoPlayerPage",
  components: {
      VideoPlayer, CheckoutBlock
  },
  computed:{
      ...mapGetters(['dict','config','order','current_video','video_pause']),
  },
  data(){
      return {
          startCheckout: false,
      }
  },
  methods:{
      ...mapMutations(['UpdateOrder']),
      onPaymentMethodSelected: function(){
          this.checkoutWidgetVisible = false;
          if(this.order.payment_method === 'o'){
              // 选择了代购方式
              this.daigoWidgetVisible = true;
              this.redeemWidgetVisible = false;
          }else{
              // 选择了其他的兑换方式
              this.redeemWidgetVisible = false;
              this.payDirectWidgetVisible = true;
          }
      },
      onStartCheckout: function(){
          this.startCheckout = true;
      },
      checkoutDone: function(){
        this.startCheckout = false;
      },
      checkoutCancelled: function(){
          this.startCheckout = false;
      },
  }
};
</script>
<style scoped>

</style>