// if (location.protocol !== 'https:') {
//   location.replace(`https:${location.href.substring(location.protocol.length)}`);
// }
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
