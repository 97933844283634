<!--
    Usage:
    1: Optional - autoDismiss: pass 0 to disable Auto Dismiss behavior. Seconds( 2 seconds ) or Milliseconds (2000 milliseconds) accepted
    2: Optional - title: Notification box title
    3: Optional - type: Notification box type: 'Note'|'Success'|'Error'
    4: Required - msg: Message to show

    Notes:
    Notification has z-50, shall always be on top layer
-->
<template>
    <div>
        <div
            v-if="!dismissed"
            class="z-50 fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
        >
            <div :class="isSuccess ? 'bg-green-500' : (isNote?'bg-gray-700': 'bg-red-500')" class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <svg
                                v-if="isSuccess"
                                class="h-6 w-6 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <!--  For information -->
                            <svg
                                v-else-if="isNote"
                                class="h-6 w-6 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                />
                            </svg>
                            <!--  For Error -->
                            <svg
                                v-else-if="isError"
                                class="h-6 w-6 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                />
                            </svg>
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm font-medium text-white">
                                {{ title }}
                            </p>
                            <p
                                class="mt-1 text-sm text-white"
                                v-html="msg"
                            />
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <button
                                class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                @click="dismiss($event)"
                            >
                                <span class="sr-only">Close</span>
                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Constants from "../../common/Constants";
    import {mapMutations} from 'vuex';
    export default {
        name: "Notification",
        props:{
            msg:{
                type: String,
                required: true
            },
            type:{
                type: String,
                required: false,
                default: function(){
                    return Constants.MSG_TYPE.NOTE;
                }
            },
            autoDismiss: {
                type: Number,
                required: false,
                default: function(){
                    return Constants.NOTIFICATION_AUTO_DISMISSED_IN;
                }
            },
            title: {
                type: String,
                required: false,
                default: function(){
                    return 'Done!'; // Used as success or note
                }
            }
        },
        data(){
            return {
                dismissed: false
            }
        },
        computed: {
            isNote: function(){
                return this.type === Constants.MSG_TYPE.NOTE;
            },
            isSuccess: function (){
                return this.type === Constants.MSG_TYPE.SUCCESS;
            },
            isError: function (){
                return this.type === Constants.MSG_TYPE.ERROR;
            }
        },
        mounted() {
            if (this.autoDismiss > 0){
                // Error notification must be dismissed manually
                const that = this;
                const timeout = this.autoDismiss > 999 ? this.autoDismiss : this.autoDismiss * 1000;
                setTimeout(function (){
                    that.dismiss();
                }, timeout)
            }
        },
        methods:{
            dismiss: function (){
                this.dismissed = true;
                this.DestroyLastNotification();
            },
            ...mapMutations([
                'DestroyLastNotification'
            ])
        }
    }
</script>

<style scoped>

</style>
