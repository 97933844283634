<template>
    <div class="bg-black flex flex-col mt-4 px-0 py-2 sm:px-6 lg:px-8 h-screen">
        <div>
            <h2 class="text-white text-2xl text-center mb-4">女主天地</h2>
        </div>
        <div class="bg-black px-2 grid grid-cols-3 gap-4 md:grid-cols-9">
            <div class="flex justify-center" v-for="(q,qIdx) in queens_search" :key="qIdx + 'q'">
                <QueenCard
                    :queen="q"
                    @queen-clicked="showDetail"
                ></QueenCard>
            </div>
        </div>
        <QueenDetail 
            :visible="detailVisible" 
            :queen="currentQueen"
            @closed="onDetailClosed"
            @video-selected="playVideo"
        ></QueenDetail>
        <FooterBar :search="false" @pn-updated="reloadQueens" :type="type"></FooterBar>
        <Pagination class="hidden md:block" :search="false" :type="type" @pn-updated="reloadQueens"></Pagination>
    </div>
</template>
<script>
import Constants from '../common/Constants';
import {mapGetters, mapMutations, mapActions} from 'vuex';
import QueenCard from '../components/Widgets/QueenCard.vue';
import FooterBar from '../components/FooterBar.vue';
import Pagination from '../components/Widgets/Pagination.vue';
import QueenDetail from '../components/Widgets/QueenDetail.vue';
export default {
    name: 'Queens',
    components:{
        QueenCard,FooterBar,Pagination,QueenDetail
    },
    computed:{
        ...mapGetters(['queens_search']),
    },
    data(){
        return {
            type: Constants.LIST_TYPE.QUEEN,
            // Queen details
            currentQueen: {},
            detailVisible: false,
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.UpdateCurrentPageVideosType(this.type); // 更新一下当前的视频类型
            this.reloadQueens();
        }); 
    },
    methods:{
        ...mapMutations(['UpdateCurrentPageVideosType']),
        ...mapActions(['LoadQueens']),
        reloadQueens: function(){
            this.LoadQueens();
        },
        showDetail: function(payload){
            this.currentQueen = payload.queen;
            this.detailVisible = true;
        },
        onDetailClosed: function(){
            this.detailVisible = false;
        },
        playVideo: function(payload){
            this.$router.push({
                name:'Watch',params:{id: payload.video.i}
            });
        }
    }
}
</script>
<style scoped>

</style>