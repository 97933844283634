<template>
    <div v-if="visible">
        <div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0" aria-hidden="true"></div>

    <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
      <div class="w-screen max-w-md">
        <form class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
          <div class="flex-1 h-0 overflow-y-auto">
            <div class="py-6 px-4 bg-gray-800 sm:px-6">
              <div class="flex items-center justify-between">
                <h2 class="text-lg font-medium text-white" id="slide-over-title">
                  Use Cryptocurrency
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button @click="backToPrev($event)" type="button" class="bg-red-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="px-2 divide-y divide-gray-200 sm:px-2">
                <div class="space-y-3 pt-6 px-3 pb-5">
                  <p class="text-sm leading-6">
                    Our system is upgrading, so sorry we can't accept the payment recently.
                  </p>
                  
                </div>
                <div>
                  <img src="line.jpeg" class="w-48 h-48">
                </div>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 px-4 py-4 flex justify-end">
            <span>
                <button @click="backToPrev($event)" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ dict.GoBack }}
                </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name: 'Crypto',
    props:{
        visible:{
            type: Boolean,
            required: true,
        }
    },
    computed:{
        ...mapGetters(['dict','config','user','current_video']),
    },
    methods:{
        backToPrev: function(e){
            e.preventDefault();
            this.$emit('cancelled');
        },
        contact: function(e){
            e.preventDefault();
        },
        goToBuy: function (type){
            let l;
            if (type === 'vip'){
              l = this.config.payment_url
                  + 'b?u='+this.user.id
                  + '&e='+localStorage.email
                  + '&p='+this.current_video.i
                  + '&k='+this.config.vip_price
                  + '&t='+Date.now();
            }else{
              l = this.config.payment_url
                  + 'b?u='+this.user.id
                  + '&e='+localStorage.email
                  + '&p='+this.current_video.i
                  + '&k=4000'
                  + '&t='+Date.now();
            }
            window.open(l,'_blank');
        }
    }
}
</script>