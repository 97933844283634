import Vue from "vue";
import Vuex from "vuex";
import Constants from "../common/Constants";
import Helpers from "../common/Helpers";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refresh_key:1, // 这个是用来强制组件进行刷新用的key值
    config:{
      base_url:null,    // api 服务器的base url
      static_base:null, // 静态内容服务器的base url
      m_base:null,      // 静态视频内容服务器的base url
      payment_url:null, // For Payment
      vip_price_fortnight: 700, // 每两周的价格
      vip_price_weekly: 400,    // 单独购买1周的价格
      vip_price: 1200,
      package_price: 400, // 15币包的价格
      collection_price: 800, // 合集价格
      ex_rate: 500, // 汇率
      discount_season: 0.1,//季付优惠
      discount_annual: 0.15,//年付优惠
      qq: 'zimu7seven@gmail.com',
      pm: '',// 系统开放的可用的支付方式
    },
    pay_urls:[], // 系统可以使用的支付网址
    current_lang: Constants.LANG.EN,
    dict: Constants.DICT.en,
    user:{
      id: null,
      name: null,
      expired_at: null,
      vip_expired_at: null,
      savings: 0,
      ioc: false,
      ap:''// 该用户可以使用的支付方式
    },
    // 视频列表, 视频属性 id, duration, title, owner:{face,name,fans表示S的id}
    videos:[],
    videos_search:[], // 视频搜索的结果集合
    queens_search:[], // S搜索的结果集合
    categories:[],
    // 当前的视频
    current_video:{
      id: null,
      title: null,
    },
    video_pause: false, // 是否暂停播放当前视频
    // 提示消息
    notifications:[],
    // 支付
    order:{},
    last_search_query:'', // 最后一个搜索关键字
    submenu_visible: false,
    // 页码
    current_page_number: 1,
    pages_count:0,
    current_page_video_type: 0, // 默认是首页的普通视频类型
    // 翻页控件使用，页码管理
    pn:{
      home:{
        param:'',
        num:1
      }, // 一般视频
      custom:{
        param:'',
        num:1
      }, // 重
      search:{
        param:'',
        num:1
      }, // 搜索
      queen:{
        param:'',
        num:1
      },  // s页
      my_videos:{
        param:'',
        num:1,
      }, // 我的视频页
      my_favorite:{
        param:'',
        num:1,
      }, // 我的收藏页
    },
    // 合集
    products:[],
    // Announcements
    announcements:[],
    // Ads
    ads:[],
    // 弹幕, 当前视频的
    diss:[],
    current_video_offset: -1, // 当前正在播放的视频的时间偏移量
  },
  mutations: {
    UpdateCurrentVideoOffset: (state, offset) => {
      state.current_video_offset = offset.t;
    },
    UpdateDiss: (state, ds) => {
      state.diss = ds;
    },
    UpdateProducts: (state, ps) => {
      state.products = ps;
    },
    // 更新当前页面的视频列表类型
    UpdateCurrentPageVideosType: (state, t) => {
      state.current_page_video_type = t;
    },
    // 更新和首页相关的页码
    UpdateHomePn: (state, data) => {
      state.pn.home.num = data.num;
      state.pn.home.param = data.param;
    },
    // 保存用户最后一个搜索的关键字
    UpdateLastSearchQuery: (state, q) => {
      state.last_search_query = q;
    },
    SwitchLang: (state, lang) => {
      state.current_lang = lang;
      if(lang === Constants.LANG.CN){
        // 请求切换成中文
        state.dict = Constants.DICT.cn;
      }else {
        // 请求切换成外语
        state.dict = Constants.DICT.en;
      }
    },
    InitUser: (state, user) => {
      state.user = user;
    },
    DecreaseSavings: (state, n) => {
      state.user.savings = state.user.savings - n;
    },
    UpdateSavings: (state, n) => {
      state.user.savings = n;
    },
    UpdateConfig: (state, conf) => {
      // 服务器的返回数据进行了简化，这里恢复成正确的键值
      state.config.base_url = conf.b;
      state.config.static_base = conf.c;
      state.config.m_base = conf.m;
      state.config.payment_url = conf.p;
      state.config.vip_price = conf.v;
      state.config.package_price = conf.pp;
      state.config.qq = conf.q;
      state.config.pm = conf.pm;//可用的支付方式
      if(conf.ppw !== undefined){
        state.config.vip_price_weekly = conf.ppw;
      }
      if(conf.ppf !== undefined){
        state.config.vip_price_fortnight = conf.ppf;
      }
    },
    UpdateConfigExtra:(state, extra) => {
      // 把和价格相关的参数填写进去
      state.config.vip_price_weekly = extra[0]*100;
      state.config.vip_price_fortnight = extra[1]*100;
      state.config.vip_price = extra[2]*100;
      state.config.package_price = extra[3]*100;
      state.config.collection_price = extra[4]*100;
      state.config.discount_season = extra[5]/100;
      state.config.discount_annual = extra[6]/100;
      state.config.ex_rate = extra[7];
      window.zm_ex_rate = extra[7];
    },
    DestroyLastNotification: (state) => {
      state.notifications.pop();
    },
    Notify: (state, notification) => {
      state.notifications.unshift(notification);
    },
    UpdateVideos: (state, videos) => {
      state.videos = videos;
    },
    UpdateSearchVideos: (state, videos) => {
      state.videos_search = videos;
    },
    UpdateCategories: (state, cats) => {
      state.categories = cats;
    },
    UpdatePayUrls: (state, urls) => {
      state.pay_urls = urls;
    },
    UpdateCurrentVideo: (state, video) => {
      state.current_video = video;
    },
    UpdateCurentPageNumber: (state, data) => {
      // 采用深度的clone方式，来让vuex的对象属性自动相应
      const clonePn = Object.assign({}, state.pn);
      switch(state.current_page_video_type){
        case Constants.LIST_TYPE.SIMPLE:
          clonePn.home.num  = data.num;
          clonePn.home.param  = data.param;
          break;
        case Constants.LIST_TYPE.RICH:
          clonePn.custom.num  = data.num;
          clonePn.custom.param  = data.param;
            break;
        case Constants.LIST_TYPE.SEARCH:
          clonePn.search.num  = data.num;
          clonePn.search.param  = data.param;
          break;
        case Constants.LIST_TYPE.QUEEN:
          clonePn.queen.num  = data.num;
          clonePn.queen.param  = data.param;
          break;
        case Constants.LIST_TYPE.MY_VIDEOS:
          clonePn.my_videos.num  = data.num;
          clonePn.my_videos.param  = data.param;
          break;
        default:
          break;
      }
      state.pn = clonePn;
    },
    UpdatePagesCount: (state, count) => {
      state.pages_count = count;
    },
    UpdateOrder: (state, order) => {
      state.order = order;
    },
    UpdateRefreshKey: (state) => {
      state.refresh_key++;
    },
    PauseVideo: (state) => { // 暂停视频播放
      state.video_pause = true;
    },
    GrantCurrentVideo: (state, url) => {
      state.current_video.url = url;
      state.current_video.grant = 1;
    },
    UpdateSubmenuVisible: (state, b) => {
      state.submenu_visible = b;
    },
    UpdateUserExpire: (state, expire) => { // 更新用户的VIP过期时间
      state.user.expired_at = expire;
    },
    UpdateUserSavings: (state, saving) => { // 更新用户的savings
      state.user.savings = saving;
    },
    UpdateQueens: (state, qs) => {
      state.queens_search = qs;
    },
    UpdateAnnouncements: (state, ans) => {
      state.announcements = ans;
    },
    UpdateAds: (state, ads) => {
      state.ads = ads;
    }
  },
  getters: {
    current_video_offset: state => {
      return state.current_video_offset;
    },
    diss: state => {
      return state.diss;
    },
    announcements: state => {
      return state.announcements;
    },
    ads: state => {
      return state.ads;
    },
    products: state => {
      return state.products;
    },
    // 当前用户是否VIP
    IsVipNow: state => {
      if(state.user.vip_expired_at && state.user.vip_expired_at !== ''){
        const expiredAt = new Date(state.user.vip_expired_at);
        return expiredAt.getTime() > (new Date()).getTime();
      }
      return false;
    },
    // 获取当前页面正确的视频类型的page number信息对象
    current_pn_obj: state => {
      switch(state.current_page_video_type){
        case Constants.LIST_TYPE.SIMPLE:
          return state.pn.home;
        case Constants.LIST_TYPE.RICH:
          return state.pn.custom;
        case Constants.LIST_TYPE.SEARCH:
          return state.pn.search;
        case Constants.LIST_TYPE.QUEEN:
          return state.pn.queen;
        case Constants.LIST_TYPE.MY_VIDEOS:
          return state.pn.my_videos;
        case Constants.LIST_TYPE.MY_FAVORITE:
          return state.pn.my_favorite;
        default:
          break;
      }
      return {};
    },
    // 一般视频页的页码对象
    current_page_video_type: state => {
      return state.current_page_video_type; // 返回当前的正在显示的视频列表类型
    },
    pn_favorite: state => {
      return state.my_favorite;
    },
    pn_home: state => {
      return state.pn.home;
    },
    pn_custom: state => {
      return state.pn.custom;
    },
    pn_search: state => {
      return state.pn.search;
    },
    pn_queen: state => {
      return state.pn.queen;
    },
    pn_my_videos: state => {
      return state.pn.my_videos;
    },
    videos_search: state => {
      return state.videos_search;
    },
    queens_search: state => {
      return state.queens_search;
    },
    last_search_query: state => {
      return state.last_search_query;
    },
    order: state => {
      return state.order;
    },
    user: state => {
      return state.user;
    },
    current_lang: state => {
      return state.current_lang;
    },
    dict: state => {
      return state.dict;
    },
    videos: state => {
      return state.videos;
    },
    categories: state => {
      return state.categories;
    },
    current_video: state => {
      return state.current_video;
    },
    config: state => {
      return state.config;
    },
    notifications: state => {
      return state.notifications;
    },
    current_page_number: state=>{
      return state.current_page_number;
    },
    pages_count: state=>{
      return state.pages_count;
    },
    refresh_key: state=>{
      return state.refresh_key;
    },
    video_pause: state => {
      return state.video_pause;
    },
    submenu_visible: state => {
      return state.submenu_visible;
    },
    pay_urls: state => {
      return state.pay_urls;
    }
  },
  actions: {
    VerifyKami(context, kami){
      return new Promise((resolve, reject) => {
        // 验证卡密
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        axios.post(
          context.getters.config.base_url + Constants.API.ORDER.VERIFY_KAMI, {
            u:uid,k:kami
          }
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data);
          }else{
            reject(res.data.msg);
          }
        });
      })
    },
    PushDiss(context, content){
      // 发弹幕
      return new Promise((resolve, reject) => {
        // 一定要取到已经存在的uid, 发送给api服务，来判定当前用户的状态
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        axios.post(
          context.getters.config.base_url + Constants.API.VIDEO.DIS, {
            u:uid,v:context.getters.current_video.i,t:context.getters.current_video_offset,c:content
          }
        ).then(res => {
          if(Helpers.isResOk(res)){
            const diss = context.getters.diss;
            diss.push(content);
            context.commit('UpdateDiss',diss);
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      })
    },
    LoadDiss(context){
      // 根据当前的时间标记，去服务器加载弹幕
      return new Promise((resolve, reject) => {
        // 一定要取到已经存在的uid, 发送给api服务，来判定当前用户的状态
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        axios.get(
          context.getters.config.base_url + 
          Constants.API.VIDEO.FETCH_DISS + 
          '?t=' + context.getters.current_video_offset + 
          '&v=' + context.getters.current_video.i + 
          '&u=' + uid
        ).then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateDiss',res.data.data.dms);
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      })
    },
    LoadVideo(context,vid){
      return new Promise((resolve, reject) => {
        // 一定要取到已经存在的uid, 发送给api服务，来判定当前用户的状态
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        // 一定要获取的fingerPrint
        const fp = Helpers.getFingerPrint();
        axios.get(
          context.getters.config.base_url + 
          Constants.API.VIDEO.GET + 
          '?lang=' + context.getters.current_lang + 
          '&v=' + vid + 
          '&u=' + uid +
          '&fp=' + fp
        ).then(res => {
          if(Helpers.isResOk(res)){
            // 这个时候，服务器返回了最新的用户数据
            // const u = res.data.data.u;
            // if(u){
            //   localStorage.vip_expired_at = u.vip_expired_at;
            //   context.commit('InitUser',u);
            //   context.commit('UpdateConfigExtra',u.sec);
            // }
            Helpers.updateLocalUser(context, res.data.data.u);
            context.commit('UpdateCurrentVideo',res.data.data.v);
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      })
    },
    /**
     *
     * @param context
     * @param params: {type:Number,pageNumber:?Number}
     * @returns {Promise<unknown>}
     * @constructor
     */
    FetchVideos(context, params){
      // Todo: 检查FetchVideos时的params的使用
      return new Promise((resolve, reject) => {
        // 一定要取到已经存在的uid, 发送给api服务，来判定当前用户的状态
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        let pageNumber = context.getters.current_pn_obj.num;
        if(params.pageNumber !== undefined){
          pageNumber = params.pageNumber;
        }
        axios.get(
          context.getters.config.base_url + 
          Constants.API.VIDEO.FETCH_VIDEOS + 
          '?o=t&lang=' + context.getters.current_lang + 
          '&page=' + pageNumber +
          '&u=' + uid +
          '&t=' + params.type
        ).then(res => {
          if(Helpers.isResOk(res)){
            Helpers.updateLocalUser(context, res.data.data.u);
            if(res.data.data.videoList.length>0){
              context.commit('UpdateVideos',res.data.data.videoList);
            }
            context.commit('UpdateCategories',res.data.data.categoryList);
            context.commit('UpdatePagesCount',res.data.data.total);
            context.commit('UpdateAnnouncements',res.data.data.anns); // 更新公告
            context.commit('UpdateAds',res.data.data.as); // 更新广告
            resolve();
          }else{
            reject(res.data);
          }
        });
      })
    },
    // 放弃当前设备, 用来通知后台，用户已经切换到新设备
    SwitchDevice(context){
      return new Promise((resolve, reject) => {
        // 一定要取到已经存在的uid, 发送给api服务，来判定当前用户的状态
        let uid = context.getters.user.id ? context.getters.user.id : ''
        if(uid === '' && localStorage.uid){
          uid = localStorage.uid;
        }
        // 一定要获取的fingerPrint
        const fp = Helpers.getFingerPrint();
        axios.get(
          context.getters.config.base_url + 
          Constants.API.USER.SWITCH_DEVICE + 
          '?lang=' + context.getters.current_lang + 
          '&u=' + uid +
          '&fp=' + fp
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      })
    },
    LoginAction(context, payload) {
      payload.fp = Helpers.getFingerPrint();
      return new Promise((resolve, reject) => {
        axios.post(
          context.getters.config.base_url + Constants.API.USER.LOGIN,
          payload
        ).then(res => {
          if(Helpers.isResOk(res)){
            // context.commit('InitUser',res.data.data);
            // context.commit('UpdateConfigExtra',res.data.data.sec);
            Helpers.updateLocalUser(context,res.data.data);
            resolve(res.data);
          }else{
            reject(res.data);
          }
        });
      })
    },
    // User registration
    SignUpAction(context, payload) {
      payload.fp = Helpers.getFingerPrint();
      return new Promise((resolve, reject) => {
        axios.post(
          context.getters.config.base_url + Constants.API.USER.SIGN_UP,
          payload
        ).then(res => {
          if(Helpers.isResOk(res)){
            // context.commit('InitUser',res.data.data);
            // context.commit('UpdateConfigExtra',res.data.data.sec);
            Helpers.updateLocalUser(context, res.data.data);
            resolve(res.data);
          }else{
            reject(res.data);
          }
        });
      })
    },
    InitConfig(context) {
      return new Promise((resolve, reject) => {
        // 初始化基本配置: Constants.API.GET_CONFIG 换成加载一个json 文件
        axios.get('/c.json').then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateConfig',res.data.data);
            resolve(res.data);
          }else{
            reject(res.data);
          }
        });
      });
    },
    FetchUser(context, uid) {
      return new Promise((resolve, reject) => {
        // Fetch user data by uid
        axios.get(
          context.getters.config.base_url + Constants.API.USER.FETCH + '?id=' + uid
        ).then(res => {
          if(Helpers.isResOk(res)){
            const u = res.data.data;
            u.id = uid;
            Helpers.updateLocalUser(context,u);
            resolve(res.data);
          }else{
            reject(res.data);
          }
        });
      });
    },
    BuyVideoByPoints(context){
      return new Promise((resolve, reject) => {
        // Fetch user data by uid
        axios.get(
          context.getters.config.base_url + Constants.API.VIDEO.BUY_BY_POINT + '?u=' + context.getters.user.id + '&v='+context.getters.current_video.i
        ).then(res => {
          if(Helpers.isResOk(res)){
            // 确认减去点数
            context.commit('DecreaseSavings',context.getters.current_video.pr);
            // 更新视频的url为返回的值
            context.commit('GrantCurrentVideo', res.data.data.url);
            resolve();
          }else{
            reject(res.data);
          }
        });
      });
    },
    /**
     * User to update his password
     * @param {*} context 
     * @param String pwd 
     * @returns 
     */
    UpdatePasswordAction(context, pwd){
      return new Promise((resolve, reject) => {
        // Fetch user data by uid
        axios.post(
          context.getters.config.base_url + Constants.API.USER.UPDATE_PWD,
          {uuid:context.getters.user.id,password:pwd}
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res);
          }else{
            reject(res.data);
          }
        });
      });
    },
    SearchAction(context,pn){
      if(!pn){
        pn = 1;
      }
      return new Promise((resolve, reject) => {
        // Fetch user data by uid
        axios.get(
          context.getters.config.base_url + Constants.API.VIDEO.SEARCH + '?q=' + context.getters.last_search_query + '&u=' + context.getters.user.id + '&l=' + context.getters.current_lang + '&pn=' + pn
        ).then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateSearchVideos',res.data.data.videoList);
            // context.commit('UpdateCurentPageNumber', pn);
            context.commit('UpdatePagesCount',res.data.data.total);
            resolve({total:res.data.data.total});
          }else{
            reject(res.data);
          }
        });
      });
    },
    DownloadVideo(context){
      return new Promise((resolve, reject) => {
        // Fetch video download link
        axios.post(
          context.getters.config.base_url + Constants.API.VIDEO.DOWMLOAD + '?u=' + context.getters.user.id + '&v=' + context.getters.current_video.i
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    // 发起支付的功能
    SubmitToCreateOrder(context, quantity){
      return new Promise((resolve, reject) => {
        // Fetch video download link
        axios.post(
          context.getters.config.base_url + Constants.API.ORDER.CREATE,
          {
            u:context.getters.user.id,o:context.getters.order,q:quantity
          }
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    // 支付成后，客户可以手动的激活自己的VIP
    ActivateMyVip(context){
      return new Promise((resolve, reject) => {
        // Fetch video download link
        axios.post(
          context.getters.config.base_url + Constants.API.ORDER.ACTIVATE,
          {u:context.getters.user.id}
        ).then(res => {
          if(Helpers.isResOk(res)){
            // 如果成功激活, 则应该返回新的过期时间和一个标志
            if(res.data.data.result === 1){
              // 表示激活了, 进行更新的操作
              localStorage.vip_expired_at = res.data.data.expire;
              context.commit('UpdateUserExpire',res.data.data.expire);
              context.commit('UpdateUserSavings',res.data.data.savings);
              resolve();
            }else{
              reject(res.data.msg);
            }
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    // 获取S的数据
    LoadQueens(context) {
      return new Promise((resolve, reject) => {
        axios.get(
          context.getters.config.base_url + Constants.API.QUEEN.FETCH_QUEENS+'?page='+context.getters.current_pn_obj.num
        ).then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateQueens', res.data.data.queens);
            context.commit('UpdatePagesCount',res.data.data.total);
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    LoadQueenLatestVideos(context,q){
      return new Promise((resolve, reject) => {
        axios.get(
          context.getters.config.base_url + Constants.API.QUEEN.LATEST_VIDEOS+'?id='+q.id+'&name='+q.name+'&lang='+context.getters.current_lang+'&u='+context.getters.user.id
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    LoadCollection(context, pageNumber){
      return new Promise((resolve, reject) => {
        axios.get(
          context.getters.config.base_url + Constants.API.COLLECTION.FETCH+'?lang='+context.getters.current_lang+'&u='+context.getters.user.id+'&page='+pageNumber
        ).then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateProducts',res.data.data.ps);
            resolve(res.data.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    LoadCollectionVideo(context, pid){
      return new Promise((resolve, reject) => {
        axios.get(
          context.getters.config.base_url + Constants.API.COLLECTION.FETCH_VIDEOS+'?lang='+context.getters.current_lang+'&u='+context.getters.user.id+'&pid='+pid
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    SendEnquiry(context, form){
      form.u = context.getters.user.id;
      return new Promise((resolve, reject) => {
        axios.post(
          context.getters.config.base_url + Constants.API.USER.JOIN_ENQ,{form: form}
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve();
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    SaveFavorate(context, vid){
      return new Promise((resolve, reject) => {
        axios.post(
          context.getters.config.base_url + Constants.API.VIDEO.FAV,{v:vid,u:context.getters.user.id}
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    LikeIt(context, vid){
      return new Promise((resolve, reject) => {
        axios.post(
          context.getters.config.base_url + Constants.API.VIDEO.THUMB_UP,{v:vid}
        ).then(res => {
          if(Helpers.isResOk(res)){
            resolve(res.data);
          }else{
            reject(res.data.msg);
          }
        });
      });
    },
    redeemCollection(context, productId){
      return new Promise((resolve, reject) => {
        axios.post(
            context.getters.config.base_url + Constants.API.COLLECTION.REDEEM,{p:productId,u:context.getters.user.id}
        ).then(res => {
          if(Helpers.isResOk(res)){
            context.commit('UpdateSavings',res.data.data.savings);// 更新用户最新的币数
            resolve();
          }else{
            reject(res.data.msg);
          }
        }).catch(err => {
          reject(err);
        });
      });
    },
    // 使用VIP兑换custom的方法
    DeductMyVipForCustom(context, vid){
      return new Promise((resolve, reject) => {
        axios.post(
            context.getters.config.base_url + Constants.API.VIDEO.DEDUCT_VIP_FOR_CUSTOM,{v:vid,u:context.getters.user.id}
        ).then(res => {
          if(Helpers.isResOk(res)){
            localStorage.vip_expired_at = res.data.data.expire;
            context.commit('UpdateUserExpire',res.data.data.expire);
            // 更新视频的url为返回的值
            context.commit('GrantCurrentVideo', res.data.data.url);
            resolve();
          }else{
            reject(res.data.msg);
          }
        }).catch(err => {
          reject(err);
        });
      });
    }
  },
  modules: {},
});
