<template>
    <div class="flex justify-around gap-2 mt-2">
        <div class="p-1 sm:p-0" v-for="(src,idx) in pics" :key="src">
            <img :src="src" class="object-cover shadow-lg rounded-sm" @click="showLarge(idx)">
        </div>

        <div v-if="large" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div @click="hideLarge()" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div class="inline-block align-bottom px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto flex items-center justify-center max-h-full rounded-full">
                            <img :src="pics[currentIndex]">
                        </div>
                    </div>
                    <div class="mt-2 flex justify-between">
                        <div @click="prev()" class="h-12 w-12 flex items-center justify-center bg-gray-800 text-base font-medium text-white rounded-full">
                        &lt;
                        </div>
                        <div @click="hideLarge()" class="h-12 w-12 flex items-center justify-center bg-gray-800 text-base font-medium text-white rounded-full">
                        X
                        </div>
                        <div @click="next()" class="h-12 w-12 flex items-center justify-center bg-gray-800 text-base font-medium text-white rounded-full">
                        &gt;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Gallery',
    props:{
        pics:{
            type: Array,
            require: true
        }
    },
    data(){
        return {
            currentIndex: 0,
            large: false,
        }
    },
    methods:{
        showLarge: function(idx){
            this.currentIndex = idx;
            this.large = true;
        },
        hideLarge: function(){
            this.large = false;
        },
        prev: function(){
            if(this.currentIndex > 0){
                this.currentIndex--;
            }
        },
        next: function(){
            if(this.currentIndex < this.pics.length-1){
                this.currentIndex++;
            }
        }
    }
}
</script>