<template>
  <div class="bg-gray-50 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {{ dict.txt_update_pwd }}
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ dict.txt_new }}{{ dict.Password }}
                    </label>
                    <div class="mt-1">
                        <input v-model="password" :placeholder="dict.sign_up_pwd_ph" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                    <label for="password" class="block text-sm font-medium text-gray-700">
                        {{ dict.confirm_password }}
                    </label>
                    <div class="mt-1">
                        <input v-model="password_confirm" :placeholder="dict.sign_up_pwd_re_ph" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div>
                <button @click="updatePasswordConfirm($event)" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <Spinner :visible="inAction"></Spinner>{{ dict.txt_update_pwd }}
                </button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import Helpers from '../../common/Helpers';
import Spinner from '../Widgets/Spinner.vue';
export default {
  name: "UpdatePwdForm",
  components:{
      Spinner
  },
  props: {
    dict: Object,
  },
  computed:{
    ...mapGetters(['current_lang'])
  },
  data(){
      return {
          inAction:false,
          password:'',
          password_confirm: '',
      }
  },
  methods:{
      ...mapActions(['UpdatePasswordAction']),
      ...mapMutations(['Notify']),
      updatePasswordConfirm: function(e){
          e.preventDefault();
          if(this.password.trim() !== this.password_confirm.trim()){
              const msg = Helpers.errorNotification(this.dict.txt_pwd_no_same);
              this.Notify(msg);
              return;
          }

          this.inAction = true;
          this.UpdatePasswordAction(this.password.trim()).then( res =>{
            if(Helpers.isResOk(res)){
                // 显示消息
                const msg = Helpers.successNotification(this.dict.txt_update_pwd_done);
                this.Notify(msg);
                this.$emit('pwd-updated');
            }else{
                const msg = Helpers.errorNotification(res.data.msg);
                this.Notify(msg);
            }
          }).catch(err => {
            // Update action failed
            const msg = Helpers.errorNotification(err.msg);
            this.Notify(msg);
          }).finally(()=>{
              this.inAction = false;
          });
      }
  }
};
</script>
<style scoped>

</style>
