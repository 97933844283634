<template>
    <div v-if="start" class="z-50">
        <Checkout
            :visible="start && checkoutWidgetVisible"
            :order="order"
            @payment-method-selected="onPaymentMethodSelected"
            @cancelled="onCheckoutCancelled"
        ></Checkout>
        <RedeemCode
            :visible="start && redeemWidgetVisible"
            @cancelled="redeemCancelled"
        ></RedeemCode>
        <Daigo
            :visible="start && daigoWidgetVisible"
            @cancelled="daigoCancelled"
        ></Daigo>
        <Crypto
            :visible="start && payCryptoVisible"
            @cancelled="payCryptoCancelled"
        ></Crypto>
        <EgiftCard 
            :visible="start && payCreditCardVisible" 
            @cancelled="payCreditCardCancelled"
        ></EgiftCard>
        <PayDirect 
            :visible="start && payDirectWidgetVisible" 
            @cancelled="payDirectCancelled"
        ></PayDirect>
        <AppleCard
            :visible="start && payAppleCardVisible"
            @cancelled="payAppleCardCancelled"
        ></AppleCard>
        <ThirdPartyCard
            :visible="start && thirdPartyCardVisible"
            @cancelled="thirdPartyCardCancelled"
        ></ThirdPartyCard>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import Checkout from './Checkout/Checkout.vue';
import RedeemCode from './Checkout/RedeemCode.vue';
import Daigo from './Checkout/Daigo.vue';
import Crypto from './Checkout/Crypto.vue';
import EgiftCard from './Checkout/EgiftCard.vue';
import PayDirect from './Checkout/PayDirect.vue';
import AppleCard from './Checkout/AppleCard.vue';
import ThirdPartyCard from './Checkout/ThirdPartyCard.vue'
export default {
    name: 'CheckoutBlock',
    props:{
        start:{
            type: Boolean,
            required: true,
        }
    },
    components:{
        Checkout,RedeemCode,Daigo,Crypto,EgiftCard,PayDirect,AppleCard,ThirdPartyCard
    },
    computed:{
      ...mapGetters(['dict','config','order','current_lang']),
    },
    data(){
      return {
          checkoutWidgetVisible: false,
          redeemWidgetVisible: false,
          daigoWidgetVisible: false,
          payDirectWidgetVisible: false,
          payCryptoVisible: false,
          payCreditCardVisible: false,
          payAppleCardVisible: false,
          thirdPartyCardVisible: false,
      }
    },
    watch:{
        'start': function(v, ov){
            if(v && !ov){
                this.checkoutWidgetVisible = true;
            }
        }
    },
    methods:{
        ...mapMutations(['UpdateOrder']),
        onPaymentMethodSelected: function(){
          this.checkoutWidgetVisible = false;
          if(this.order.payment_method === 'o'){
              // 选择了代购方式
              this.daigoWidgetVisible = true;
              this.redeemWidgetVisible = false;
          }
          else if(this.order.payment_method === 'b'){
              // 选择了虚拟币
              this.payCryptoVisible = true;
              this.redeemWidgetVisible = false;
          }
          else if(this.order.payment_method === 'c'){
              // 选择了信用卡
              this.payCreditCardVisible = true;
              this.redeemWidgetVisible = false;
          }
          else if(this.order.payment_method === 'apple'){
            // 选择了苹果礼品卡
            this.payAppleCardVisible = true;
            this.redeemWidgetVisible = false;
          }
          else if(this.order.payment_method === 't'){
            // 选择了苹果礼品卡
            this.thirdPartyCardVisible = true;
            this.redeemWidgetVisible = false;
          }
          else{
              // 选择了其他的兑换方式
              this.redeemWidgetVisible = false;
              this.payDirectWidgetVisible = true;
          }
        },
        onCheckoutCancelled: function(){
            this.checkoutWidgetVisible = false;
            this.$emit('checkout-cancelled');
        },
        redeemCancelled: function(){
          this.redeemWidgetVisible = false;
          this.checkoutWidgetVisible = true;
        },
        daigoCancelled: function(){
          this.daigoWidgetVisible = false;
          this.checkoutWidgetVisible = true;
        },
        payDirectCancelled: function(){
          this.payDirectWidgetVisible = false;
          this.checkoutWidgetVisible = true;
        },
        payCryptoCancelled: function(){
          this.payCryptoVisible = false;
          this.checkoutWidgetVisible = true;
        },
        payCreditCardCancelled: function(){
          this.payCreditCardVisible = false;
          this.checkoutWidgetVisible = true;
        },
        payAppleCardCancelled: function(){
          this.payAppleCardVisible = false;
          this.checkoutWidgetVisible = true;
        },
        thirdPartyCardCancelled: function(){
          this.thirdPartyCardVisible = false;
          this.checkoutWidgetVisible = true;
        }
    }
}
</script>