import Constants from "./Constants";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
const Helpers = {
    isResOk: res => {
        return res.status === Constants.API.RES.OK && res.data.code === Constants.API.RES.GOOD;
    },
    successNotification: (msg, title) => {
        const n = {
            msg:msg, type: Constants.MSG_TYPE.SUCCESS, title:Constants.MSG_TYPE.SUCCESS
        };
        if (title!==undefined && title !== ''){
            n.title = title;
        }
        return n;
    },
    errorNotification: (msg, title) => {
        const n = {
            msg:msg, type: Constants.MSG_TYPE.ERROR, title:Constants.MSG_TYPE.ERROR
        };
        if (title!==undefined && title !== ''){
            n.title = title;
        }
        return n;
    },
    noteNotification: (msg, title) => {
        const n = {
            msg:msg, type: Constants.MSG_TYPE.NOTE, title:Constants.MSG_TYPE.NOTE
        };
        if (title!==undefined && title !== ''){
            n.title = title;
        }
        return n;
    },
    nonce: (len) => {
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz023456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < len; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    isVip: () => {
        if(localStorage.vip_expired_at && localStorage.vip_expired_at !== ''){
            const expiredAt = new Date(localStorage.vip_expired_at);
            return expiredAt.getTime() > (new Date()).getTime();
        }
        return false;
    },
    /**
     * 把传入的数字转换成可读的文本
     * @returns string
     * @param points
     * @param special
     */
    priceText: (points, special=undefined) => {
        let p;
        if(points >= 200){
            // 表示服务器端的价格还没有变换
            p = (points/100).toFixed(1);
        }else{
            p = (points/4).toFixed(1);
        }
        if(special !== undefined){
            if(special){
                if(localStorage.lang === 'cn'){
                    return '本片需' + (p*4) + '币(相当于'+(p*window.zm_ex_rate)+'元)';
                }else{
                    return 'AUD $' + p;
                }
            }
            else{
                // 非special的资源
                if(localStorage.lang === 'cn'){
                    return '兑换本片需 ' + points + '个币';
                }else{
                    return 'Current video costs ' + points + ' coins';
                }
            }
        }
        else{
            if(localStorage.lang === 'cn'){
                return '人民币'+(p*window.zm_ex_rate).toFixed(1)+'元';
            }else{
                return 'AUD $' + p;
            }
        }
    },
    packagePriceText: (multi, price) => {
        const lang = localStorage.lang;
        let discount = '';
        let discountValue = 0;
        switch (multi){
            case 2:
                discount = lang === 'cn' ? '优惠5%' : '5% Off';
                discountValue = 0.05;
                break;
            case 3:
                discount = lang === 'cn' ? '优惠7%' : '7% Off';
                discountValue = 0.07;
                break;
            case 4:
                discount = lang === 'cn' ? '优惠10%' : '10% Off';
                discountValue = 0.1;
                break;
            case 7:
                discount = lang === 'cn' ? '优惠15%' : '15% Off';
                discountValue = 0.15;
                break;
            case 10:
                discount = lang === 'cn' ? '优惠20%' : '20% Off';
                discountValue = 0.2;
                break;
            default:
                break;
        }
        const p = price * (1-discountValue)/100;
        if(lang === 'cn'){
            return '约'+(p*Constants.EX_RATE).toFixed(2)+'元/' + discount;
        }else{
            return 'AUD $'+p.toFixed(2) + '/' + discount;
        }
    },
    buyVipBtnTxt1: (lang, num)=>{
        if(lang === 'cn'){
            // exRate = !exRate ? Constants.EX_RATE : exRate;
            return '点击购买VIP会员 '+(num*window.zm_ex_rate/100).toFixed(0)+'元';
        }else{
            return 'Buy VIP, AUD $'+(num/100).toFixed(1)+'/Month';
        }
    },
    buyVipBtnTxt2: (lang)=>{
        if(lang === 'cn'){
            return '4周有效期内看全站资源(代售订制类的除外)';
        }else{
            return 'Unlimited Access VIP videos(Private/Custom excluded)';
        }
    },
    buyPkgBtnTxt1: (lang, num)=>{
        if(lang === 'cn'){
            return '点击购买¥'+(num*window.zm_ex_rate/100).toFixed(0)+'元(15个币)';
        }else{
            return '15 Coins package, AUD $'+(num/100).toFixed(0);
        }
    },
    buyPkgBtnTxt2: (lang)=>{
        if(lang === 'cn'){
            return '可用于兑换任意视频,使用无有效期限制';
        }else{
            return 'Redeem any videos, including private videos';
        }
    },
    redeemBtnTxt1: (lang, current, cost)=>{
        if(lang === 'cn'){
            return '我还有'+current+'个币, 使用'+cost+'个币观看本片';
        }
        else{
            return 'Use '+cost+' coins to watch it';
        }
    },
    getFingerPrint: ()=>{
        if(!localStorage.fingerPrint){
            const fpPromise = FingerprintJS.load();
            (async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise;
                const result = await fp.get();
                // This is the visitor identifier:
                localStorage.fingerPrint = result.visitorId
                return localStorage.fingerPrint;
              })()
        }else{
            return localStorage.fingerPrint;
        }
    },
    /**
     * 统一更新本地用户数据的方法
     * @param context
     * @param u: {vip_expired_at: string, sec: Object}
     */
    updateLocalUser: (context, u) => {
        if(u){
            localStorage.vip_expired_at = u.vip_expired_at;
            context.commit('InitUser',u);
            context.commit('UpdateConfigExtra',u.sec);
            context.commit('UpdatePayUrls', u.ap_url);
        }
    }
};

export default Helpers;