<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pl-2 pr-2 bg-black" v-if="isVideoDataReady">
            <div>
                <div v-if="!isVip && !isCustomVideo" class="py-2 mt-2 rounded-md overflow-hidden bg-green-500 mb-2">
                    <a href="#" @click="gotoCheckoutPage(type_vip,config.vip_price,$event)">
                        <h2 class="text-white text-center font-bold text-lg">{{ buyVipBtnTxt1 }}</h2>
                        <p class="text-white text-center text-sm">
                            {{ buyVipBtnTxt2 }}
                        </p>
                    </a>
                </div>

                <div v-if="!isVip && !granted && noMoreSavings" class="py-2 rounded-md overflow-hidden bg-blue-500 mb-2">
                    <a href="#" @click="gotoCheckoutPage(type_package,config.package_price,$event)">
                        <h2 class="text-white text-center font-bold text-lg">{{ buyPkgBtnTxt1 }}</h2>
                        <p class="text-white text-center text-sm">
                            {{ buyPkgBtnTxt2 }}
                        </p>
                        <p class="text-center text-sm mt-1">
                            {{ priceText }}
                        </p>
                    </a>
                </div>

                <div v-if="isCustomVideo && !granted && !canUsePoints" class="py-2 rounded-xl overflow-hidden bg-blue-500 mb-4">
                    <a href="#" @click="gotoCheckoutPage(type_custom_video,current_video.pr,$event)">
                        <h2 class="text-white text-center font-bold text-lg">{{ dict.txt_custom_video }}</h2>
                        <p class="text-white text-center text-sm">
                            {{ priceText }}
                        </p>
                    </a>
                </div>

                <div v-if="isCustomVideo && !granted && canUseVipToRedeemCustom" class="py-2 rounded-xl overflow-hidden bg-green-500 mb-4">
                    <a href="#" @click="confirmToDeductVipForCustom(current_video.i,$event)">
                        <h2 class="text-white text-center font-bold text-lg">{{ dict.txt_use_vip_for_custom_video }}</h2>
                        <p class="text-white text-center text-sm">
                            {{ dict.txt_to_deduct }} {{ current_video.vds }} {{ dict.txt_days }}
                        </p>
                    </a>
                </div>

                <div v-if="canUsePoints && !granted" class="py-2 rounded-md overflow-hidden bg-green-500 mt-2">
                    <a href="#" @click="buyWithMyPoints($event)">
                        <h2 class="text-white text-center font-bold text-lg">{{ dict.txt_redeem_this }}</h2>
                        <p class="text-white text-center text-sm">
                            {{ redeemBtnTxt1 }}
                        </p>
                    </a>
                </div>

                <div class="pt-4 rounded-xl overflow-hidden bg-gray-900 sm:mb-4 flex">
                    <a>
                        <img 
                            class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" 
                            :src="config.static_base + current_video.o.f" 
                        >
                    </a>
                    <p class="text-yellow-400 m-2" v-html="current_video.t" style="max-width: 60%;"></p>
                    <Danmu @diss-updated="onDmUpdated" @diss-updated-local="onDmUpdatedLocal"></Danmu>
                </div>

                <div class="p-4 rounded-xl overflow-hidden bg-gray-900 mb-4 hidden md:block">
                    <div v-for="b in banners" :key="b.p">
                        <a :href="b.a" target="_blank">
                            <img :src="b.p + '.jpg'">
                        </a>
                    </div>
                </div>
                <div class="p-4 rounded-xl overflow-hidden bg-gray-900 mb-4 hidden md:block">
                    <p class="text-white">{{ dict.txt_claim }}</p>
                </div>
            </div>
            <div class="video-player-wrap mb-4 z-10 relative">
                <div id="danmu-pool" ref="danmuPool"></div>
                <video id="video" ref="tsVideo" controls></video>

                <div class="flex justify-around bbg">
                    <NiceBtn
                    v-if="(isVip && !isCustomVideo) || granted" class="pt-4 mb-4"
                    :title="dict.txt_download" @pressed="download" 
                    :spin="downloading">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </NiceBtn>
                    <NiceBtn
                        class="pt-4 mb-4"
                        :title="dict.txt_like" :nc="thumbUpColor" @pressed="thumbUp">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                        </svg>                          
                    </NiceBtn>
                    <NiceBtn
                        v-if="(isVip && !isCustomVideo) || granted" class="pt-4 mb-4"
                        :title="dict.txt_save" :nc="favColor" @pressed="fav">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                        </svg>                     
                    </NiceBtn>
                    <a href="mailto:zimu7seven@gmail.com">
                        <NiceBtn
                            class="pt-4 mb-4" :title="dict.txt_report">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
                            </svg>                                 
                        </NiceBtn>
                    </a>  
                </div>
                <BaiduPanBlock :txt="bdLink" v-if="bdLink!==null"></BaiduPanBlock>
                <Gallery :pics="thumbnails" class="bbg pb-2"></Gallery>
            </div>
            <div>
                <ul class="grid grid-cols-2 md:grid-cols-3 gap-2 bg-black">
                    <video-card
                        v-for="(video, idx) in related_videos"
                        :key="idx"
                        :video="video"
                        :dict="dict"
                        :sb="config.static_base"
                        @video-selected="onVideoSelected"
                    ></video-card>
                </ul>
            </div>
        </div>
        <Overlay :visible="!isVideoDataReady || resourceLoading" :text="dict.loading_video"></Overlay>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import Hls from 'hls.js';
import axios from "axios";
import Constances from "../../common/Constants";
import Helpers from "../../common/Helpers";
import VideoCard from "./VideoCard.vue";
import Overlay from "./Overlay.vue";
import Gallery from "./Gallery.vue";
import Constants from '../../common/Constants';
import Danmu from './Diss/Danmu.vue';
import BulletJs from "js-bullets";
import BaiduPanBlock from './BaiduPanBlock.vue';
import NiceBtn from './NiceBtn.vue';
export default {
  name: "VideoPlayer",
  components:{
      VideoCard,Overlay,Gallery,Danmu,BaiduPanBlock,NiceBtn
  },
  props:{
      pause:{
          type:Boolean,
          required: true,
      }
  },
  computed:{
      ...mapGetters(['user','current_video','dict','config','current_lang','diss']),
      isVip: function(){
          return Helpers.isVip();
      },
      type_vip: function(){
          return Constances.ORDER.TYPE.VIP;
      },
      type_package: function(){
          return Constances.ORDER.TYPE.PACKAGE;
      },
      type_custom_video: function(){
          return Constances.ORDER.TYPE.CUSTOM_VIDEO;
      },
      type_collection: function(){
          return Constances.ORDER.TYPE.COLLECTION;
      },
      isVideoDataReady: function(){
          return this.current_video.i;
      },
      isCustomVideo: function(){
          return this.current_video.s === 1;
      },
      priceText: function(){
          // 这里检查一下，是否用户当前剩下的点数，是否足够兑换当前视频
          return Helpers.priceText(this.current_video.pr, this.isCustomVideo);
      },
      canUsePoints: function(){
          if (this.current_video.pr >= 200){
            return this.user.savings >= this.current_video.pr/25;
          }
          return this.user.savings >= this.current_video.pr;
      },
      granted: function(){
          return this.current_video.grant === 1;
      },
      thumbnails: function(){
          const thumbnails = [];
          const tmp = this.current_video.url.split('/');
          tmp[tmp.length -1] = this.current_video.i + 'thumb_000';
          const prefix = '/' + tmp.join('/');
          for(let i=1;i<=4;i++){
              thumbnails.push(this.config.static_base + prefix + i + '.jpg');
          }
          return thumbnails;
      },
      buyVipBtnTxt1: function(){
          return Helpers.buyVipBtnTxt1(this.current_lang, this.config.vip_price);
      },
      buyVipBtnTxt2: function(){
          return Helpers.buyVipBtnTxt2(this.current_lang);
      },
      buyPkgBtnTxt1: function(){
          return Helpers.buyPkgBtnTxt1(this.current_lang, this.config.package_price);
      },
      buyPkgBtnTxt2: function(){
          return Helpers.buyPkgBtnTxt2(this.current_lang);
      },
      redeemBtnTxt1: function(){
          return Helpers.redeemBtnTxt1(this.current_lang, this.user.savings, this.current_video.pr);
      },
      downloadLink: function(){
          let link = this.buildSourceFullUrl();
          return link.replace('.m3u8','.mp4');
      },
      noMoreSavings: function(){
          return this.user.savings < this.current_video.pr;
      },
      canUseVipToRedeemCustom: function(){
        return this.current_video.vds > 1;
      }
  },
  watch:{
      // 监控vuex中的变化的可用方式
      '$store.getters.video_pause': function(val,old){
          if(val&&!old){
              this.pausePlaying();
          }
      }
  },
  data(){
      return {
          related_videos:[],
          banners:[],
          resourceLoading: true,
          downloading: false,
          dmScreen: null,
          dmIntervalAction: null,
          bdLink:null,
          favColor:'',
          thumbUpColor:''
      }
  },
  mounted(){
    if(!this.isVideoDataReady){
        // 如果 store 里面没有 current video, 则去服务器端加载
        this.LoadVideo(this.$route.params.id).then(()=>{
            this.playVideo();
            // 加载推荐视频
            this.loadRealatedVideos();
        }).catch(err => {
          const msg = Helpers.errorNotification(err);
          this.Notify(msg);
          this.$router.push({
            name: 'Home'
          })
        });
    }
    else{
        this.playVideo();
        // 加载推荐视频
        this.loadRealatedVideos();
    }
    scroll(0,0);
  },
  methods:{
    ...mapMutations(['UpdateCurrentVideo','UpdateOrder','UpdateRefreshKey','Notify','UpdateCurrentVideoOffset']),
    ...mapActions(['LoadVideo','BuyVideoByPoints','DownloadVideo','SaveFavorate','LikeIt','DeductMyVipForCustom']),
    // 弹幕操作
    onDmUpdated: function(payload){
        if(payload.active){
            // 表示弹幕处于激活的状态
            this.dmScreen = new BulletJs('#danmu-pool',{});
            // 一共显示9秒的弹幕
            const dissLen = this.diss.length;
            if(dissLen > 0){
                this.dmScreen.push(this.decorateDmTxt(this.diss[0], false)); // 先抛出第1条弹幕
            }
            if(dissLen > 1){
                const freq = parseInt(9000/dissLen-1);
                let dmIdx = 1;
                this.dmIntervalAction = setInterval(()=>{
                    if(dmIdx >= this.diss.length){
                        clearInterval(this.dmIntervalAction);
                    }else{
                        this.dmScreen.push(this.decorateDmTxt(this.diss[dmIdx], false));
                        dmIdx++;
                    }
                }, freq);
            }
        }
        else{
            // 弹幕关闭了
            if(this.dmIntervalAction){
                clearInterval(this.dmIntervalAction);
            }
        }
    },
    onDmUpdatedLocal: function(payload){
        if(this.dmScreen === null){
            this.dmScreen = new BulletJs('#danmu-pool',{});
        }
        this.dmScreen.push(this.decorateDmTxt(payload.c, true));
    },
    decorateDmTxt: function(txt, highlight){
        return highlight ? '<span class="text-red-500">'+txt+'</span>' : '<span>'+txt+'</span>';
    },
    /**
     * 花费自己的点数, 来购买视频
     */
    buyWithMyPoints: function(e){
        e.preventDefault();
        this.resourceLoading = true;
        this.BuyVideoByPoints().then(()=>{
            this.pausePlaying();
            this.playVideo();
            const msg = Helpers.successNotification('购买成功, All Done!');
            this.Notify(msg);
            this.resourceLoading = false;
        }).catch(err => {
            const msg = Helpers.errorNotification(err.msg);
            this.Notify(msg);
            this.resourceLoading = false;
        }).finally(() => {
            this.resourceLoading = false;
        });
    },
    /** 使用VIP时长来兑换custom */
    confirmToDeductVipForCustom: function(vid, e){
        e.preventDefault();
        this.DeductMyVipForCustom(vid).then(()=>{
            this.pausePlaying();
            this.playVideo();
            const msg = Helpers.successNotification('购买成功, All Done!');
            this.Notify(msg);
            this.resourceLoading = false;
        }).catch(err => {
            const msg = Helpers.errorNotification(err.msg);
            this.Notify(msg);
            this.resourceLoading = false;
        }).finally(() => {
            this.resourceLoading = false;
        });
    },
    gotoCheckoutPage: function(orderType, price, e){
        e.preventDefault();
        // Create an order and update to the store, for VIP/Package purchaing
        const tempOrder = {
            type: orderType,
            price: price,
            productId: this.current_video.i,
            payment_method: null,
            quantity: 1,  // 默认数量设置为1, 对package类型有用
        };
        this.UpdateOrder(tempOrder);
        this.$emit('checkout',{order: tempOrder});
    },
    buildSourceFullUrl: function(){
        return 'https://ns' + this.current_video.l + '.' + this.config.m_base + '/' + this.current_video.url;
    },
    playVideo: function(){
        if (Hls.isSupported()){
            const hls = new Hls();
            hls.attachMedia(this.$refs.tsVideo);
            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                hls.loadSource(this.buildSourceFullUrl());
                hls.on(Hls.Events.MANIFEST_PARSED, ()=>{
                    this.resourceLoading = false; // 视频资源加载完毕并且可以播放
                    this.$refs.tsVideo.video.play();
                });
                hls.on(Hls.Events.FRAG_CHANGED, (event, data)=>{
                    // fired when fragment matching with current video position is changing 当新的fragment被加载播放
                    this.UpdateCurrentVideoOffset({
                        t:parseInt(data.frag.start),
                        e:event
                    });
                })
            });
        }else{
            // 苹果手机会出现的问题
            this.$refs.tsVideo.src = this.buildSourceFullUrl();
            this.$refs.tsVideo.addEventListener('loadedmetadata', () => {
                this.resourceLoading = false; // 视频资源加载完毕并且可以播放
                this.$refs.tsVideo.play();
            });
        }
    },
    pausePlaying: function(){
        if(this.video){
            this.video.pause();
        }
    },
    // 加载推荐视频
    loadRealatedVideos: function(){
        axios.get(
            this.config.base_url + Constances.API.VIDEO.RELATED_VIDEOS + 
            '?q=q&name=' + this.current_video.o.n + 
            '&lang=' + this.current_lang + 
            '&u=' + this.user.id + 
            '&i=' + this.current_video.o.i +
            '&v=' + this.current_video.i
        ).then(res => {
            if(Helpers.isResOk(res)){
                this.related_videos = res.data.data.vs;
                this.banners = res.data.data.bs;
                // 注意，这里检查一下是否vip已经过期, 逻辑为 如果服务器指示说vip过期，并且本地判断为VIP，则启动更新的机制
                let vipUpdated = false;
                if(Helpers.isVip()){
                    if(res.data.data.u !== 1){
                        // 过期了
                        vipUpdated = true;
                    }
                }else{
                    // 本地缓存数据判断，不是VIP
                    if(res.data.data.u !== 0){
                        // 服务器指示，vip开通了
                        vipUpdated = true;
                    }
                }
                if(vipUpdated){
                    localStorage.vip_expired_at = res.data.data.t;
                    this.UpdateRefreshKey();
                }
            }
        });
    },
    onVideoSelected: function(payload){
        scroll(0,0);
        this.UpdateCurrentVideo(payload.video);
        this.playVideo();
    },
    download: function(){
        if(!this.downloading){
            // 加入延迟, 避免用户重复的点击
            this.downloading = true;
            this.DownloadVideo().then(res => {
                if(res.code === Constants.API.RES.GOOD){
                    // 成功获取到下载链接
                    if(res.data.bd && this.current_lang==='cn'){
                        this.bdLink = res.data.bd;
                        this.downloading = false;
                    }
                    else{
                        window.open(res.data.l,'_blank');
                    }
                }
            }).catch(err => {
                const msg = Helpers.errorNotification(err);
                this.Notify(msg);
            }).finally(() => {
                setTimeout(() => {
                    this.downloading = false;
                }, 4000);
            });
        }
    },
    // 收藏视频
    fav: function(){
        if(this.favColor.length===0){
            this.SaveFavorate(this.current_video.i).then(res=>{
                let msg;
                if(res.code === Constants.API.RES.GOOD){
                    msg = Helpers.successNotification(res.msg);
                }else{
                    msg = Helpers.errorNotification(res.msg);
                }
                this.Notify(msg);
                this.favColor = 'bg-red-700'
            }).catch(err => {
                const msg = Helpers.errorNotification(err);
                this.Notify(msg);
            })
        }
    },
    thumbUp: function(){
        if(this.thumbUpColor.length===0){
            this.LikeIt(this.current_video.i).then(res=>{
                let msg;
                if(res.code === Constants.API.RES.GOOD){
                    msg = Helpers.successNotification(res.msg);
                }else{
                    msg = Helpers.errorNotification(res.msg);
                }
                this.Notify(msg);
                this.thumbUpColor = 'bg-red-700'
            }).catch(err => {
                const msg = Helpers.errorNotification(err);
                this.Notify(msg);
            })
        }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-player-wrap #video{
    width: 100%;
}
.video-player-wrap #danmu-pool{
    position: absolute;
    width: 100%;
    height: 240px;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
}
.bbg{
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
</style>