<template>
  <div id="app">
    <nav-bar-new
      :key="refresh_key"
      @lang-change="onLangChange"
    ></nav-bar-new>
    <div class="bg-black main-wrap z-0" v-if="ready">
      <div class="p-holder md:mb-8"></div>
      <div v-if="announcements.length > 0">
        <Announce :announce="ann" v-for="(ann, aidx) in announcements" :key="aidx+'an'"></Announce>
      </div>
      <router-view />
    </div>
    <Notifications></Notifications>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import NavBarNew from "./components/NavBarNew.vue";
import Notifications from "./components/Notifications.vue";
import Helpers from './common/Helpers';
import Announce from './components/Widgets/Announce.vue';
export default {
  name: "App",
  components:{
    NavBarNew,Notifications,Announce
  },
  computed:{
    ...mapGetters(['current_lang','dict','config','refresh_key','announcements','ads']),
  },
  data(){
    return {
      ready: false,
    }
  },
  created(){
    if(localStorage.lang){
      this.SwitchLang(localStorage.lang);
    }
    this.init();
  },
  methods:{
    ...mapMutations(['SwitchLang','Notify']),
    ...mapActions(['InitConfig','FetchUser']),
    onLangChange: function(payload){
      // console.log('语言切换了, 进行需要采取的操作 。。。 ' + payload.lang);
      localStorage.lang = payload.lang;
      // 从新加载首页
      window.location.href = location.protocol + '//' + location.host;
    },
    init(){
      this.InitConfig().then(()=>{
        // 获取配置数据成功之后，检查 local storage 中是否保存了 uid, 如果保存了，则自动加载用户
        if(localStorage.uid && localStorage.uid !== ''){
          this.FetchUser(localStorage.uid);
        }
        this.ready = true;
      }).catch(error => {
        const msg = Helpers.errorNotification(error.msg);
        this.Notify(msg);
      });
    },
  }
};
</script>

<style>
.p-holder{
  width: 100%;
  height: 50px;;
}
</style>
